.l-footer {
  width: 100%;
  background-color: #2B2B2B;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 1366px;
    min-height: 185px;
    margin: auto;
    padding: 40px 90px 40px 150px;
  }
  &__navi {
    display: flex;
    color: #fff;
  }
  &__naviItem {
    width: 120px;
    a {
      display: block;
      font-size: 1.6rem;
      line-height: 2;
    }
  }
  &__policy {
    width: 150px;
    a {
      display: block;
      font-size: 1.4rem;
      line-height: 2;
      white-space: nowrap;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__logo {
    display: block;
    width: 211px;
    height: 50px;
    img {
      height: 50px;
    }
  }
  &__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 1.3rem;
    background-color: #fff;
  }
  @include media(1365px){
    &__wrapper {
      width:100%;
    }
  }
  @include media(sp){
    &__wrapper {
      position: relative;
      min-height: 120px;
      padding: 20px 23px;
    }
    &__naviItem {
      width: 65px;
      a {
        font-size: 1.1rem;
      }
    }
    &__policy {
      a {
        font-size: 1.1rem;
      }
    }
    &__inner {
      position: absolute;
      height: 24px;
      bottom: 23px;
      right: 23px;
    }
    &__logo {
      width: 104px;
      img {
        height: 24px;
      }
    }
    &__copyright {
      height: 35px;
      font-size: .9rem;
      }
  }
}
.l-sns {
  margin-bottom: 32px;
  &__item {
    display: inline-block;
    width: 25px;
    height: 25px;
    &+& {
      margin-left: 15px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  @include media(sp){
    margin-bottom: 30px;
    &__item {
      display: inline-block;
      width: 18px;
      height: 18px;
      &+& {
        margin-left: 10px;
      }
    }
  }
}