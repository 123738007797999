.l-header {
  position: fixed;
  width: 1132px;
  max-width: 100%;
  margin: auto;
  padding: 0 13px;
  background-color: $c_bg;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 26px 0 30px;
    border-bottom: solid 1px #C3C1C1;
  }
  &--top {
    width: 1306px;
  }
  &--top &__inner {
    border-bottom: none;
    padding: 26px 37px 20px;
  }
  &__logo {
    display: flex;
    align-items: center;
    a {
      display: block;
      width: 211px;
      img {
        height: 50px;
      }
    }
  }
  &__copy {
    position: relative;
    margin-left: 21px;
    padding-left: 22px;
    font-size: 1.6rem;
    line-height: 1.5625;
    span::before,
    span::after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      width: 1px;
      height: 45px;
      background-color: #000;
    }
    span::before {
      left: 0;
    }
    span::after {
      left: 5px;
    }
  }
  @include media(tab){
    &__inner {
      padding: 12px 0;
    }
    &--top &__inner{
      margin: 0;
      padding: 13px 37px;
    }
    &__logo {
      a {
        width: 104px;
        img {
          height: 24px;
        }
      }
    }
    &__copy {
      margin-left: 8px;
      padding-left: 8px;
      font-size: 1rem;
      line-height: 1.5;
      top: -1px;
      span::before,
      span::after {
        top: 3px;
        height: 25px;
      }
      span::after {
        left: 3px;
      }
    }
  }
  @include media(sp){
    &--top &__inner{
      padding: 13px 0;
    }
  }
}
.l-navi {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  &__spButton {
    display: none;
  }
  &__sp {
    display: none;
  }
  &__item {
    text-align: center;
    padding-bottom: 12px;
    &:hover,
    &.current {
      background-image: url(../../assets/images/icon_square_green.svg);
      background-position: center bottom;
      background-size: 7px 7px;
      background-repeat: no-repeat;
    }
    span {
      display: block;
      line-height: 1;
      &.jp {
        font-size: 1.6rem;
        font-family: $f_serif;
        padding: 0 25px 2px;
        font-weight: 500;
      }
      &.en {
        padding: 0 5px;
        font-size: 1.1rem;
        font-family: $f_baskervill;
      }
    }
    &--icon {
      text-align: center;
      padding: 0 15px 12px 0;
      &:hover {
        opacity: .7;
      }
      @include media (tab) {
        padding: 0 10px 12px 10px;
      }
    }
  }
  &__icon {
    display: flex;
    margin: 0 20px 0 45px;
    @include media (tab) {
      margin: 0 0 8% 0;
    }
  }
  &__sns {
    display: flex;
    align-items: center;
    margin: 0 35px 0 45px;
  }
  &__snsItem {
    width: 25px;
    height: 25px;
    &+& {
      margin-left: 15px;
    }
  }
  &__langSwitch {
    font-family: $f_serif;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    position: relative;
    @include media (tab) {
      margin-bottom: 30px;
    }
    .lang{
      display: flex;
      align-items: center;
      img {
        max-height: 12px;
        width: auto;
        border: solid 1px #DDD;
        margin-right: 6px;
      }
    }
  }
  &__language {
    padding-bottom: 16px;
    border-bottom: solid 1px #707070;
    background-image: url(../../assets/images/icon_angle_bottom_green.svg);
    background-position: center bottom 8px;
    background-size: 8px 8px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
  &__langList {
    padding: 10px; 
    position: absolute;
    top: 100%;
    left: -24%;
    background-color: #fff;
    line-height: 1.5;
    li {
      &:not(:first-child) {
        margin-top: 10px;
        @include media (tab) {
          margin-top: 16px;
        }
      }
    }
    @include media (tab) {
      position: static;
      padding: 16px 0;
      background-color: transparent;
    }
  }
  @include media(tab) {
    position: fixed;
    top: 52px;
    right: -100vw;
    width: 100vw;
    height: calc(100vh - 52px);
    flex-direction: column;
    align-items: center;
    padding-top: 16%;
    background-color: rgba(255,255,255);
    z-index: 10;
    transition: right ease-in-out .8s;
    background-image: url(../../assets/images/bg_map_japan.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
    &.active {
      right: 0;
    }
    &__item {
      margin-bottom: 8%;
      &+.l-navi__sp {
        margin-top: 100px;
      }
    }
    &__spButton {
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      cursor: pointer;
      z-index: 20;
      span {
        position: absolute;
        right: 0;
        content: "";
        display: block;
        height: 1px;
        background-color: #707070;
        &:nth-of-type(1) {
          width: 32px;
        }
        &:nth-of-type(2) {
          width: 27px;
          top: 7px;
        }
        &:nth-of-type(3) {
          width: 22px;
          top: 14px;
        }
      }
      &.active span {
        &:first-child {
          transform: rotate(-30deg);
          width: 36px;
          top: 7px;
        }
        &:nth-of-type(2) {
          display: none;
        }
        &:last-child {
          width: 36px;
          top: 7px;
          transform: rotate(30deg);
        }
      }
    }
    &__sp {
      display: block;
      font-size: 1.3rem;
      line-height: 2;
      &.copyright {
        position: absolute;
        bottom: 2%;
        font-size: .9rem;
      }
    }
  }
}