@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap');
//==========================================================
// base
// 基本CSS
// - base
//==========================================================

@import "base/_base";

//========================================================== 
// node_modules 
// npmでインストールしたモジュールのスタイル 
// - slick 
//========================================================== 

@import "../../node_modules/slick-carousel/slick/slick.scss"; 

//==========================================================
// base
// 基本CSS
// - header
// - layout
// - footer
//==========================================================
@import "layout/_header";
@import "layout/_layout";
@import "layout/_footer";