// common
.l-breadcrumbs {
  width: 1132px;
  max-width: 100%;
  padding: 21px 13px 40px;
  margin: auto;
  ul {
    display: flex;
    align-items: center;
  }
  li {
    position: relative;
    padding-right: 10px;
    line-height: 1;
    font-family: $f_serif;
    font-size: 1.2rem;
  }
  li + li {
    padding-left: 20px;
    &::before {
      content: ">";
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
    }
  }
  @include media(tab) {
    padding: 8px 13px 15px;
    li {
      padding-right: 0;
      font-size: 8px;
    }
    li + li {
      padding-left: 12px;
    }
  }
}


.l-main {
  //width: 1366px;
  margin: auto;
  padding: 97px 0 0;
  @include media(1365px){
    width:100%;
  }
  @include media(tab){
    padding: 55px 0 0;
  }
}

.l-productLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &::before,
  &::after,
  span::before,
  span::after {
    position: absolute;
    content: "";
    display: block;
    background-color: #707070;
  }
  &::before,
  &::after {
    height: 1px;
  }
  &::before {
    width: 115%;
    top: 140px;
    left: -153px;
  }
  &::after {
    width: 80%;
    top: 150px;
    right: -153px;
  }
  span::before,
  span::after {
    width: 1px;
    height: 70px;
  }
  span::before {
    top: 100px;
    left: 80%;
  }
  span::after {
    top: 120px;
    left: calc(80% + 8px);
  }
}

// top page
.l-hero {
  width: 1306px;
  max-width: 100%;
  padding: 0 50px;
  margin: auto;
  &__figure {
    position: relative;
  }
  &__img {
    img {
      width: 100%;
    }
  }
  &__caption {
    position: absolute;
    top: 50%;
    right: 17%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    padding: 10px 3px 0;
    background-color: rgba(255,255,255,.9);
    font-family: $f_serif;
    font-size: 3.2rem;
    font-weight: 600;
    letter-spacing: 0.35em;
    writing-mode: vertical-rl;
    line-height: 1;
    transform: translateY(-50%);
    span {
      display: block;
      width: 32px;
      white-space: nowrap;
      line-height: 1;
      span.dot {
        display: inline;
        writing-mode: horizontal-tb;
        height: 32px;
        margin: -22px 0 10px;
      }
    }
  }
  @include media(sp) {
    padding: 0 13px;
  }
}
.hero {
  &__slider {
    position: relative;
    z-index: 2;
    .slick-dots {
      position: absolute;
      right: 13px;
      bottom: 20px;
      li {
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background-color: #ccc;
          border-radius: 100%;
          transform: translate(-50%,-50%);
          z-index: 0;
        }
        &.slick-active {
          background-image: url(../../assets/images/icon_square_green.svg);
          background-repeat: no-repeat;
          background-size: 12px 12px;
          background-position: center center;
          &::before {
            display: none;
          }
        }
      }
      button {
        position: relative;
        width: 20px;
        height: 20px;
        text-indent: 100%;
        z-index: 1;
      }
    }
  }
  @include media(sp) {
    &__slider {
      .slick-dots {
        display: flex;
        right: 13px;
        bottom: -23px;
      }
    }
  }
}
.l-topPage {
  width: 1080px;
  max-width: 100%;
  margin: auto;
  padding: 0 10px;
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 125px 70px;
  }
  &__content {
    position: relative;
  }
  &__title {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background-repeat: no-repeat;
    &::before {
      position: absolute;
      content: "";
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
    .m--top & {
      width: 118px;
      margin: 36px 30px 0 0;
      &::before {
        top: -160px;
        left: -34px;
        width: 21px;
        height: 250px;
        background-image: url(../../assets/images/line_featured.svg);
        mask-image: url(../../assets/images/line_featured.svg);
        -webkit-mask-image: url(../../assets/images/line_featured.svg);
        background-color: #c3c1c1;
      }
    }
    .m--category & {
      height: 204px;
      margin-bottom: 30px;
      &::before {
        bottom: 0;
        left: 0;
        width: 103px;
        height: 21px;
        background-image: url(../../assets/images/line_title.svg);
        mask-image: url(../../assets/images/line_title.svg);
        -webkit-mask-image: url(../../assets/images/line_title.svg);
        background-color: #c3c1c1;
      }
    }
    .m--admin & {
      width: 67px;
      margin-top: 190px;
    }
    .en {
      font-family: $f_baskervill;
      font-size: 1.7rem;
      writing-mode: vertical-rl;
      .m--top & {
        font-size: 1.6rem;
        margin-left: 7.2px;
      }
      .m--admin & {
        font-size: 1.4rem;
      }
    }
    .jp {
      font-family: $f_serif;
      font-size: 3.5rem;
      font-weight: 600;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      letter-spacing: 0.3em;
      .m--top & {
        font-size: 2.6rem;
        line-height: 1.45;
      }
      .m--admin & {
        font-size: 2.5rem;
      }
    }
    &.icon-marine {
      background-image: url(../../assets/images/icon_marine.svg);
      background-size: 125px 85px;
      background-position: 0 20px;
    }
    &.icon-livestock {
      background-image: url(../../assets/images/icon_livestock.svg);
      background-size: 87px 76px;
      background-position: 100px 40px;
    }
    &.icon-rice {
      background-image: url(../../assets/images/icon_rice.svg);
      background-size: 94px 91px;
      background-position: 0 20px;
    }
    &.icon-fruit {
      background-image: url(../../assets/images/icon_fruit.svg);
      background-size: 104px 104px;
      background-position: 100px 20px;
    }
  }
  &__overview {
    font-family: $f_serif;
    font-size: 1.4rem;
    line-height: 1.78;
    white-space: nowrap;
    .m--top & {
      margin: 64px 0 0 0;
      font-size: 1.6rem;
      line-height: 2;
      p+p {
        padding-top: 1em;
      }
    }
    .m--category & {
      margin-bottom: 30px;
    }
  }
  &__figure {
    .m--top & {
      display: flex;
      align-items: center;
    }
    .m--top & img {
      width: 392px;
      height: auto;
    }
  }
  &__category {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      padding-right: 70px;
      .l-topPage__title {
        justify-content: flex-start;
      }
      .l-topPage__figure {
        margin-right: 20px;
      }
    }
    &:nth-of-type(odd) {
      padding-left: 70px;
      .l-topPage__title {
        justify-content: flex-end;
      }
      .l-topPage__figure {
        margin-left: 20px;
      }
    }
  }
  &__more {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #707070;
    width: 184px;
    height: 43px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-right: 14px;
      background-image: url(../../assets/images/icon_arrow_right.svg);
      background-repeat: no-repeat;
      background-size: 20px 3px;
      background-position: right 20px center;
      background-color: #fff;
      border-radius: 7px;
      border: solid 1px #707070;
      font-family: $f_serif;
      font-size: 1.4rem;
    }
  }
  &__admin {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    padding: 100px 42px 100px 0;
  }
  &__adminLine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &::before,
    &::after,
    span::before,
    span::after {
      position: absolute;
      content: "";
      display: block;
      background-color: #c1c1c1;
    }
    &::before,
    &::after {
      height: 1px;
    }
    &::before {
      width: 115%;
      top: 140px;
      left: -153px;
    }
    &::after {
      width: 80%;
      top: 150px;
      right: -153px;
    }
    span::before,
    span::after {
      width: 1px;
      height: 70px;
    }
    span::before {
      top: 100px;
      left: 80%;
    }
    span::after {
      top: 120px;
      left: calc(80% + 8px);
    }
  }
  &__adminOverview {
    margin: 230px 44px 0 60px;
    width: 201px;
    img {
      width: 201px;
      max-width: none;
      height: 270px;
    }
  }
  &__adminPictures {
    display: flex;
    // width: 655px;
    width: calc(100% - 270px);
  }
  &__adminFigure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 70.245%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    &:first-of-type {
      margin-right: 35px;
    }
    &:nth-of-type(2) {
      margin-top: 140px;
    }
    &.only-sp {
      img:nth-of-type(1) {
        opacity: 1;
        transition: all .8s ease-in-out;
        z-index: 2;
      }
      &:hover {
        img:nth-of-type(1) {
          opacity: 0;
        }
        &::before {
          background-color: #C3C3C3;
        }
        &::after {
          background-color: #4BB98E;
        }
      }
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -23px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
      &::before {
        background-color: #4BB98E;
        right: 16px;
        transition: all .8s ease-in-out;
      }
      &::after {
        background-color: #C3C3C3;
        right: 0;
        transition: all .8s ease-in-out;
      }
    }
  }
  @include media(1365px){
    .m--top & {
      margin: 36px 0 0 0;
    }
    &__admin {
      overflow: hidden;
    }
    &__adminLine {
      &::before {
        width: 90%;
      }
      &::after {
        width: 60%;
      }
      &::before {
        left: -10px;
      }
      &::after {
        right: -10px;
      }
    }
  }
  @include media(1200px) {
    &__more {
      position: static;
    }
  }
  @include media(sp) {
    padding: 0 13px;
    &__top {
      display: block;
      padding: 12% 0;
      &.m--top {
        text-align: center;
      }
    }
    &__content {
      padding: 0 5.33%;
    }
    &__title {
      .m--top & {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 0;
        margin: 0 0 5.8% 0;
        padding-top: 79.083%;
        text-align: left;
        background-image: url(../../assets/images/img_featured_sp@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right top;
        &::before {
          display: none;
        }
      }
      .m--category & {
        width: 74px;
        height: auto;
        margin: 0;
        padding-top: 70px;
        float: right;
        &::before {
          display: none;
        }
      }
      .m--admin & {
        position: absolute;
        bottom: 240px;
        right: 9.33%;
        width: 53px;
        height: 103px;
        margin: 0;
        padding: 10px 0;
        background-color: #fff;
        z-index: 5;
      }
      .en {
          font-size: 1.2rem;
          .m--top & {
          position: absolute;
          top: 0;
          left: 87px;
          font-size: 1.4rem;
          margin-left: 0;
          .m--admin & {
            font-size: 1.2rem;
          }
        }
      }
      .jp {
        font-size: 2.5rem;
        line-height: 1.4;
        .m--top & {
          position: absolute;
          top: 0;
          left: 27px;
          font-size: 2rem;
          line-height: 1.2;
        }
        .m--admin & {
          font-size: 1.8rem;
        }
      }
      &.icon-marine {
        background-size: 74px 50px;
        background-position: right top;
      }
      &.icon-livestock {
        background-size: 53px 55px;
        background-position: right top;
      }
      &.icon-rice {
        background-size: 52px 50px;
        background-position: right top;
      }
      &.icon-fruit {
        background-size: 53px 53px;
        background-position: right top;
      }
    }
    &__overview {
      min-height: 68px;
      font-size: 1.2rem;
      line-height: 1.9;
      .m--top & {
        display: inline-block;
        margin: 0;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.83;
      }
      .m--category & {
        margin: 16.39% 0 9.18%;
      }
    }
    &__figure {
      .m--top & {
        display: none;
      }
    }
    &__category {
      margin-bottom: 0;
      &:nth-of-type(even) {
        flex-direction: column-reverse;
        padding-right: 0;
        .l-topPage__figure {
          margin: 0 0 5.33% 0;
        }
      }
      &:nth-of-type(odd) {
        flex-direction: column-reverse;
        padding-left: 0;
        .l-topPage__title {
          justify-content: flex-start;
        }
        .l-topPage__figure {
          margin: 0 0 5.33% 0;
        }
      }
    }
    &__more {
      width: 126px;
      height: 27px;
      margin-bottom: calc(17.33% + 10px);
      a {
        margin-right: 0;
        font-size: .9rem;
        background-image: url(../../assets/images/icon_see_more.svg);
        background-size: 12px 3px;
        span {
          margin-left: -10px;
        }
      }
    }
    &__admin {
      position: relative;
      flex-direction: column-reverse;
      width: calc(100% + 26px);
      margin: 0 0 0 -13px;
      padding: 26.66% 0 0;
      background-image: url(../../assets/images/line_admin_sp.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
    }
    &__adminLine {
      display: none;
    }
    &__adminOverview {
      margin: 0;
      padding: 0 110px 70px 0;
      width: 100%;
      text-align: right;
      img {
        width: 160px;
        height: auto;
      }
    }
    &__adminPictures {
      width: 100%;
      margin-bottom: 28px;
    }
    &__adminFigure.only-sp {
      padding-top: 89.33%;
      &::before,
      &::after {
        bottom: -18px;
      }
      &::before {
        right: auto;
        left: 33px;
      }
      &::after {
        right: auto;
        left: 17px;
      }
    }
  }
  @include media(ss) {
    &__overview {
      .m--top & {
        font-size: 1.1rem;
      }
    }
  }
}

// archive page
.l-archive {
  width: 1106px;
  max-width: 100%;
  margin: auto;
  &__hero {
    position: relative;
    padding: 0 34px 60px;
  }
  &__heroFigure {
    width: 100%;
  }
  &__heroCaption {
    position: absolute;
    top: 50px;
    left: 148px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    span {
      margin-right: 1em;
      padding: 5px 3px;
      white-space: nowrap;
      font-size: 1.6rem;
      font-weight: 600;
      font-family: $f_serif;
      line-height: 1;
      letter-spacing: .05em;
      vertical-align: top;
      writing-mode: vertical-rl;
      background-color: rgba(255,255,255,.9);
      strong {
        display: inline-block;
        padding-bottom: 3px;
        &::before,
        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 7px;
          background-repeat: no-repeat;
          background-size: 5px 4px;
        }
        &::before {
          background-image: url(../../assets/images/icon_double_quotation_01.svg);
          background-position: right top;
        }
        &::after {
          background-image: url(../../assets/images/icon_double_quotation_02.svg);
          background-position: right center;
        }
      }
    }
  }
  &__explanation {
    display: flex;
    justify-content: space-between;
    padding: 0 34px;
  }
  &__explanationText {
    width: calc(100% - 240px);
    font-size: 1.3rem;
    line-height: 2.3;
    font-family: $f_serif;
  }
  &__title {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background-repeat: no-repeat;
    &::before {
      position: absolute;
      content: "";
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .en {
      font-family: $f_baskervill;
      font-size: 1.6rem;
      writing-mode: vertical-rl;
    }
    .jp {
      font-family: $f_serif;
      font-size: 3rem;
      font-weight: 600;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      letter-spacing: 0.3em;
    }
    &.icon-marine {
      width: 222px;
      background-image: url(../../assets/images/icon_marine.svg);
      background-size: 125px 85px;
      background-position: 0 20px;
    }
    &.icon-livestock {
      width: 222px;
      background-image: url(../../assets/images/icon_livestock.svg);
      background-size: 87px 76px;
      background-position: 40px 20px;
    }
    &.icon-rice {
      width: 222px;
      background-image: url(../../assets/images/icon_rice.svg);
      background-size: 94px 91px;
      background-position: 40px 20px;
    }
  }
  &__productLine {
    position: relative;
    width: 100%;
    height: 90px;
    margin: 46px 0;
    &::before,
    &::after,
    span::before,
    span::after {
      position: absolute;
      content: "";
      display: block;
      background-color: #c1c1c1;
    }
    &::before,
    &::after {
      height: 1px;
    }
    &::before {
      width: 87%;
      top: 40px;
      left: 34px;
    }
    &::after {
      width: 80%;
      top: 48px;
      right: 34px;
    }
    &.m--product {
      &::before {
        width: 90%;
        left: 0;
      }
      &::after {
        width: 90%;
        right: 0;
      }
    }
    span::before,
    span::after {
      width: 1px;
      height: 70px;
    }
    span::before {
      top: 0;
      left: 80%;
    }
    span::after {
      top: 20px;
      left: calc(80% - 8px);
    }
  }
  &__product {
    width: 100%;
    padding: 0 34px;
  }
  &__productTitle {
    margin-bottom: 50px;
    .en {
      position: relative;
      margin-bottom: 35px;
      font-family: $f_baskervill;
      font-size: 1.6rem;
      line-height: 1;
      &::after {
        position: absolute;
        bottom: -14px;
        left: 0;
        content: "";
        width: 35px;
        height: 1px;
        background-color: #707070;
      }
    }
    .jp {
      font-family: $f_serif;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1;
    }
  }
  @include media(1000px) {
    p + p {
      margin-top: 1em;
    }
    br.only-pc {
      display: none;
    }
  }
  @include media(sp) {
    &__hero {
      position: relative;
      padding: 0 13px;
    }
    &__heroCaption {
      display: none;
    }
    &__explanation {
      flex-direction: column-reverse;
      padding: 0 13px;
    }
    &__explanationText {
      width: 100%;
      font-size: 1.2rem;
      line-height: 2;
    }
    &__title {
      padding: 10px 5px;
      .en {
        font-size: 1.2rem;
      }
      .jp {
        font-size: 2.5rem;
        letter-spacing: 0.2em;
        line-height: 1.5;
      }
      &.icon-marine,
      &.icon-livestock,
      &.icon-rice {
        position: relative;
        top: -30px;
        margin: 0 30px -30px auto;
        width: 72px;
        height: auto;
        background-color: #fff;
        background-image: none;
        &::after {
          position: absolute;
          bottom: 26px;
          content: "";
          display: block;
          width: 73px;
          height: 50px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      &.icon-marine{
        &::after {
          right: 82px;
          background-image: url(../../assets/images/icon_marine.svg);
        }
      }
      &.icon-livestock{
        &::after {
          right: 65px;
          background-image: url(../../assets/images/icon_livestock.svg);
        }
      }
      &.icon-rice {
        &::after {
          right: 60px;
          background-image: url(../../assets/images/icon_rice.svg);
        }
      }
    }
    &__productLine {
      display: none;
    }
    &__product {
      margin: 36px 0 8%;
      padding: 22.13% 5.33% 0;
      background-image: url(../../assets/images/line_admin_sp.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
    }
    &__productTitle {
      .en {
        margin-bottom: 24px;
        font-size: 1.4rem;
        &::after {
          bottom: -9px;
          width: 20px;
        }
      }
      .jp {
        font-size: 1.8rem;
      }
    }
  }
}
.l-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 0 13px;
  &__item {
    width: 33.33%;
    margin: 0 0 70px;
    padding: 0 1.85%;
    &:hover .l-column__more p {
      opacity: .8;
    }
    img:nth-of-type(1) {
      z-index: 2;
    }
    &.twoTmb {
      a:hover {
        img:nth-of-type(1) {
          opacity: 0;
        }
        .l-column__title {
          &::before {
            background-color: #C3C3C3;
          }
          &::after {
            background-color: #4BB98E;
          }
        }
      }
    }
  }
  &__thumbnail {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      max-width: initial;
      transform: translate(-50%,-50%);
    }
  }
  &__title {
    margin-bottom: 13px;
    font-family: $f_serif;
    font-size: 1.6rem;
    font-weight: 900;
    position: relative;
    .twoTmb & {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 32px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
      &::before {
        background-color: #4BB98E;
        right: 16px;
      }
      &::after {
        background-color: #C3C3C3;
        right: 0;
      }
    }
  }
  &__description {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.83;
  }
  &__more {
    background-color: #707070;
    width: 100%;
    height: 36px;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-image: url(../../assets/images/icon_see_more.svg);
      background-repeat: no-repeat;
      background-size: 11px 4px;
      background-position: left calc(50% + 40px) center;
      background-color: #fff;
      border-radius: 7px;
      border: solid 1px #707070;
      font-family: $f_serif;
      font-size: 1.2rem;
      margin-right: 14px;
    }
  }
  @include media(sp) {
    margin-bottom: 7%;
    padding: 0;
    &__item {
      width: 48%;
      margin: 0 0 13%;
      padding: 0;
    }
    &__title {
      padding: 0 10px;
      .twoTmb & {
        &::before,
        &::after {
          width: 6px;
          height: 6px;
          bottom: 36px;
        }
        &::before {
          right: 15px;
        }
        &::after {
          right: 5px;
        }
      }
    }
    &__description {
      padding: 0 10px;
    }
    &__more {
      width: calc(100% - 20px);
      height: 30px;
      margin: 0 10px;
    }
  }
}
.l-product {
  width: 1178px;
  max-width: 100%;
  margin: auto;
  padding: 0 70px;
  &__hero {
    width: 1063px;
    max-width: 100%;
    margin: 0 auto 90px;
    padding: 0 13px;
  }
  &__heroItem {
    display: flex;
  }
  &__heroFigure {
    width: calc(100% - 340px);
    margin-right: 4.82%;
  }
  &__heroOutline {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
  }
  &__heroName {
    border: solid 1px #707070;
    outline: solid 1px #707070;
    outline-offset: 6px;
    margin: 7px 7px 37px;
    padding: 30px;
    text-align: center;
    .copy {
      font-size: 1.4rem;
    }
    .name {
      font-size: 3rem;
      font-family: $f_serif;
      font-weight: 600;
    }
    .en {
      font-size: 1.4rem;
    }
  }
  &__heroExplanation {
    min-height: 160px;
    margin-bottom: 6px;
    font-size: 1.4rem;
    line-height: 2;
    border-bottom: solid 1px #C3C1C1;
  }
  &__heroCategory {
    padding-top: 38px;
    border-top: solid 1px #C3C1C1;
    font-size: 1.8rem;
    line-height: 1.9444;
    font-family: $f_serif;
    font-weight: 500;
    dt {
      float: left;
      clear: both;
      width: 4em;
    }
    dd {
      padding-left: 4em;
    }
  }
  &__productLine {
    width: 686px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    &.m--right {
      margin-right: 128px;
      margin-left: auto;
      background-image: url(../../assets/images/line_right.svg);
      mask-image: url(../../assets/images/line_right.svg);
      -webkit-mask-image: url(../../assets/images/line_right.svg);
      background-color: #c3c1c1;
    }
    &.m--left {
      margin-left: 128px;
      margin-right: auto;
      background-image: url(../../assets/images/line_left.svg);
      mask-image: url(../../assets/images/line_left.svg);
      -webkit-mask-image: url(../../assets/images/line_left.svg);
      background-color: #c3c1c1;
    }
  }
  &__voice {
    display: flex;
    width: 1063px;
    max-width: 100%;
    margin: 0 auto 90px;
    padding: 0 13px;
  }
  &__voiceContent {
    width: 340px;
  }
  &__voiceTitle {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 0 0 46px 35px;
    .en {
      font-size: 1.4rem;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }
    .jp {
      font-size: 1.8rem;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }
  }
  &__voiceCopy {
    margin-bottom: 20px;
    font-family: $f_serif;
    font-size: 2.5rem;
    line-height: 1.68;
  }
  &__voiceName {
    margin-bottom: 46px;
    padding-right: 50px;
    text-align: right;
    .occupation {
      font-size: 1.8rem;
    }
    .name {
      font-size: 2.5rem;
    }
  }
  &__voiceText {
    font-size: 1.4rem;
    line-height: 2;
    p+p {
      margin-top: 1em;
    }
  }
  &__voiceFigure {
    width: calc(100% - 340px);
    margin: 88px 0 0 4.82%;
  }
  &__recipe {
    display: flex;
    width: 1064px;
    max-width: 100%;
    margin: 0 auto 90px;
    padding: 0 13px;
  }
  &__recipeFigure {
    width: calc(100% - 340px);
    margin: 90px 4.82% 0 0;
  }
  &__recipeContent {
    width: 340px;
  }
  &__recipeTitle {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin: 0 0 46px 26px;
    .en {
      font-size: 1.4rem;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }
    .jp {
      font-size: 1.8rem;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }
  }
  &__recipeCopy {
    position: relative;
    display: inline-block;
    padding: 0 24px;
    font-size: 1.8rem;
    font-family: $f_serif;
    line-height: 1.2;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      content: "";
      display: block;
      width: 18px;
      height: 100%;
      background-image: url(../../assets/images/icon_star.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  &__recipeName {
    margin-bottom: 36px;
    font-size: 2.5rem;
    font-family: $f_serif;
    line-height: 1.52;
  }
  &__recipeTime {
    position: relative;
    padding-left: 54px;
    margin-bottom: 40px;
    font-size: 3rem;
    line-height: 1.3;
    span {
      font-size: 2rem;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 42px;
      height: 42px;
      background-image: url(../../assets/images/icon_clock.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__voiceText {
    font-size: 1.4rem;
    line-height: 2;
  }
  &__recipeSns {
    margin-top: 42px;
    display: inline-flex;
    align-items: center;
  }
  &__recipeIcon {
    &:first-of-type {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    &:nth-of-type(2) {
      margin-right: 15px;
    }
  }
  &__material {
    display: flex;
    align-items: flex-start;
    width: 1064px;
    max-width: 100%;
    margin: 0 auto 114px;
    padding: 0 13px;
  }
  &__materialTitleWrapper {
    width: 187px;
  }
  &__materialTitle {
    position: relative;
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 600;
    .title {
      letter-spacing: 0.5em;
      margin-right: -0.5em;
    }
    .servings {
      font-size: 1.8rem;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
    &::before {
      bottom: -2px;
    }
    &::after {
      bottom: -8px;
    }
  }
  &__materialListWrapper {
    width: calc(100% - 187px);
    padding-top: 50px;
  }
  &__materialList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 10px 20px;
    background-color: #F5F5F5;
    font-size: 1.6rem;
    font-weight: 500;
    &.m--sub {
      color: #B10000;
    }
  }
  &__recipeSubTitle {
    margin-bottom: 4px;
    font-size: 1.6rem;
    font-weight: 600;
    &.m--sub {
      color: #B10000;
    }
  }
  &__process {
    display: flex;
    align-items: flex-start;
    width: 1064px;
    max-width: 100%;
    margin: 0 auto 40px;
    padding: 0 13px;
  }
  &__processTitleWrapper {
    width: 187px;
  }
  &__processTitle {
    position: relative;
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 600;
    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
    }
    &::before {
      bottom: -2px;
    }
    &::after {
      bottom: -8px;
    }
  }
  &__processList {
    width: calc(100% - 187px);
    margin-right: 30px;
    padding-top: 50px;
    counter-reset: processCounter;
  }
  &__processItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: solid 1px #c3c1c1;
    counter-increment: processCounter;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 36px;
      height: 36px;
      content: counter(processCounter);
      font-size: 1.6rem;
      font-weight: 900;
      line-height: 34px;
      text-align: center;
      color: #B10000;
      background-image: url(../../assets/images/bg_number.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__processText {
    padding: 0 10px 0 60px;
    font-size: 1.4rem;
  }
  &__processFigure {
    width: 370px;
  }
  @include media(tab) {
    padding: 0;
  }
  @include media(sp) {
    &__heroItem {
      flex-direction: column;
    }
    &__heroFigure {
      width: 100%;
      margin: 0 0 9.33%;
    }
    &__heroOutline {
      width: 100%;
      padding: 2.865%;
    }
    &__heroName {
      margin-bottom: 5.73%;
      .copy {
        font-size: 1.2rem;
      }
      .name {
        font-size: 2.6rem;
      }
      .en {
        font-size: 1.3rem;
      }
    }
    &__heroExplanation {
      min-height: initial;
      margin-bottom: 6.59%;
      font-size: 1.2rem;
      border-bottom: none;
      font-family: $f_serif;
    }
    &__heroCategory {
      padding-top: 0;
      font-size: 1.5rem;
      line-height: 2;
      border-top: none;
    }
    &__productLine {
      display: none;
    }
    &__voice {
      flex-direction: column;
      padding: 0 13px;
      background-image: url(../../assets/images/line_right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
    }
    &__voiceContent {
      width: 100%;
      padding: 0 2.865%;
    }
    &__voiceTitle {
      flex-direction: column;
      margin: 0 0 8%;
      padding-top: 24%;
      .en {
        writing-mode:unset;
      }
      .jp {
        writing-mode:unset;
      }
    }
    &__voiceCopy {
      font-size: 2.2rem;
      line-height: 1.59;
    }
    &__voiceFigure {
      width: auto;
      margin: 0 0 9.33%;
    }
    &__voiceName {
      margin-bottom: 37px;
      padding-right: 18px;
    }
    &__recipe {
      flex-direction: column;
      margin-bottom: 0;
      padding: 0 13px;
      background-image: url(../../assets/images/line_right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
    }
    &__recipeFigure {
      width: 100%;
      margin: 0 0 23px;
    }
    &__recipeCopy {
      margin-bottom: 10px;
    }
    &__recipeName {
      margin-bottom: 17px;
      font-size: 2.2rem;
    }
    &__recipeTime {
      margin-bottom: 32px;
      padding-left: 40px;
      font-size: 2.6rem;
      span {
        font-size: 1.6rem;
      }
      &::before {
        width: 32px;
        height: 32px;
      }
    }
    &__voiceText {
      font-size: 1.2rem;
    }
    &__recipeContent {
      width: 100%;
      padding: 0 2.865% 17.33%;
    }
    &__recipeTitle {
      flex-direction: column;
      margin: 0 0 8%;
      padding-top: 24%;
      .en {
        writing-mode:unset;
      }
      .jp {
        writing-mode:unset;
      }
    }
    &__material {
      flex-direction: column;
      width: auto;
      margin: 0 0 40px;
      padding: 0 13px;
    }
    &__materialTitleWrapper {
      width: 100%;
      margin-bottom: 26px;
      text-align: center;
    }
    &__materialTitle {
      font-size: 2rem;
      .servings {
        font-size: 1.4rem;
      }
    }
    &__materialListWrapper {
      width: 100%;
      padding: 0 2.865%;
    }
    &__materialList {
      padding: 6px 20px;
      font-size: 1.4rem;
    }
    &__recipeSubTitle {
      margin-top: 17px;
    }
    &__process {
      flex-direction: column;
      width: auto;
      padding: 0 13px;
    }
    &__processTitleWrapper {
      width: 100%;
      text-align: center;
    }
    &__processTitle {
      font-size: 2rem;
    }
    &__processList {
      width: 100%;
      margin-right: 0;
    }
    &__processItem {
      flex-direction: column;
      border-bottom: none;
      padding-bottom: 0;
    }
    &__processText {
      padding: 0 10px 30px 55px;
    }
    &__processFigure {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
.product__slider {
  .l-column__item {
    padding: 5.5%;
    transition: all .8s ease-in-out;
  }
  .slick-arrow {
    position: absolute;
    top: 25%;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: 100%;
    overflow: hidden;
  }
  .slick-prev {
    left: -60px;
    background-image: url(../../assets/images/icon_arrow_before.svg);
  }
  .slick-next {
    right: -60px;
    background-image: url(../../assets/images/icon_arrow_next.svg);
  }
  @include media(tab) {
    .l-column__item {
      width: 48%;
      margin: 0 0 13%;
      padding: 0;
    }
  }
}

.l-topPage {
  &__news {
    background-color: #f4f4f4;
    padding: 70px 0;
  }

  &__info {
    margin: 110px auto 156px;
    @include media (sp) {
      margin: 74px auto;
    } 
  }

  &__info, &__news {
    .l-topPage__more {
      position: initial;
      width: 406px;
      margin: 74px auto 0;
      max-width: calc(100% - 100px);
      @include media (sp) {
        margin-top: 32px;
      } 
    }
  }

  &__contact {
    margin: 0 auto 56px;
  }
}

.news {
  &__title {
    text-align: center;
    font-size: 2.5rem;

    .wrap {
      font-family: $f_serif;
      border: solid 1px #707070;
      background-color: #fff;
      padding: 6px;
      display: inline-block;
      font-weight: 600;
      span {
        display: inline-block;
        border: solid .5px #707070;
        padding: 18px 24px;
        line-height: 1;
        @include media (sp) {
          padding: 10px 12px;
        }
      }
    }
    
    @include media (sp) {
      font-size: 2rem;
      width: calc(100% - 20px);
      margin: 0 auto;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    margin: 65px auto 0;
    width: 1158px;
    max-width: calc(100% - 105px);
    @include media (1024px) {
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
      max-width: calc(100% - 20px);
    }
    @include media (sp) {
      margin-top: 42px;
    }
  }

  &__item {
    width: 25%;
    @include media (1024px) {
      width: 45%;
    }
    @include media (sp) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 35px;
      @include media (1024px) {
        margin-right: 0;
      }
    }

    &-figure {
      position: relative;
      width: 100%;
      padding-top: 56.25%;

      a {
        width: 100%;
        z-index: 1;
        &::before {
          content: '';
          display: inline-block;
          width: 51px;
          height: 36px;
          background-image: url(../../assets/images/ico_youtube.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          transition: .3s;
        }
        &:hover::before {
          transform: translate(-50%,-50%) scale(1.1);
        }
      }
  
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-info {
      line-height: 1.25;
      font-size: 1.4rem;
      .title {
        font-weight: 600;
        margin: 20px 0 10px;
      }
      .detail {
        .views {
          &::after {
            content: ' ・ ';
          } 
        }
      }
    }
    
  }
}

.info {
  &__title {
    text-align: center;
    .en {
      font-family: $f_baskervill;
      font-size: 1.7rem;
      @include media (sp) {
        font-size: 1.4rem;
      }
    }
    .jp {
      font-family: $f_serif;
      font-size: 3.5rem;
      font-weight: 600;
      @include media (sp) {
        font-size: 2.5rem;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    @include media (sp) {
      flex-direction: column;
      margin-top: 12px;
    }
  }

  &__item {
    width: 48%;
    border-bottom: solid .5px #C3C1C1;
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media (sp) {
      width: 100%;
      margin-top: 20px;
    }
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: .5px;
      background-color: #C3C1C1;
      margin-bottom: 6px;
    }

    &-date {
      font-size: 1.4rem;
      line-height: 1;
      @include media (sp) {
        font-size: 1.2rem;
      }
    }

    &:nth-child(1), &:nth-child(2) {
      .info__item-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::after {
          content: 'NEW';
          display: inline-block;
          width: 40px;
          height: 17px;
          font-size: 1.1rem;
          background-color: #4BB98E;
          color: #fff;
          border-radius: 2px;
          text-align: center;
          line-height: 1.5;
          @include media (sp) {
            font-size: 1rem;
            width: 32px;
            height: 16px;
          }
        }
      }
    }

    &-title {
      padding: 15px 0 20px;
      line-height: 1.25;
      @include media (sp) {
        font-size: 1.2rem;
      }
    }
  }
}


.contact {
  &__wrap {
    border: solid .5px #4BB98E;
    outline: solid 1px #4BB98E;
    outline-offset: 5px;
    margin: 6px;
    text-align: center;
  }

  &__title {
    margin-top: 30px;
    .en {
      font-size: 1.7rem;
    }
    .jp {
      font-size: 2.5rem;
    }
    @include media (sp) {
      .en {
        font-size: 1.4rem;
      }
      .jp {
        font-size: 1.8rem;
      }
    }
  }

  &__link {
    background-color: #4BB98E;
    color: #fff;
    border-radius: 5px;
    width: 780px;
    height: 70px;
    margin: 50px auto 62px;
    max-width: calc(100% - 80px);
    @include media (sp) {
      margin: 30px auto;
      height: 60px;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

// news page (ライプコマース/新着情報一覧)
.l-news {
  margin-bottom: 150px;
  @include media (1120px) {
    margin: 0 12px;
  }
  @include media (tab) {
    width: calc(100% - 24px);
    margin: 0 auto 80px;
  }
  @include media (sp) {
    width: 100%;
  }

  & .news__title {
    .wrap, .wrap span{
      display: block;
    }
  }
  
  & .news__list {
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 60px 30px;
    @include media (sp) {
      max-width: calc(100% - 24px);
      gap: 30px;
    }
  }

  & .news__item {
    width: 31.5%;
    @include media (1120px) {
      width: 30%;
    }
    @include media (sp) {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: auto;
    }
    .title {
      font-size: 1.6rem;
    }
  }
}

// contact page (お問い合わせ)
.l-contact {
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.25;
  @include media (tab) {
    padding: 0 14px;
  }

  &__top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @include media (sp) {
      flex-direction: column;
    }
    .contactByPhone {
      border: 1px solid #707070;
      padding: 5px;
      max-width: 440px;
      width: 100%;
      &__wrap {
        text-align: center;
        border: .5px solid #707070;
        padding: 20px;
        font-weight: 500;
        & p:nth-child(2) {
          font-size: 4.2rem;
          margin: 18px 0;
          @include media (sp) {
            font-size: 2.8rem;
            margin: 10px 0;
          }
        }
      }
    }
    .contactHeader {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      margin-right: 20px;
      @include media (sp) {
        margin: 40px 20px 0;
      }
      &__title {
        .en {
          font-size: 1.7rem;
          margin-bottom: 16px;
          @include media (sp) {
            font-size: 1.4rem;
            margin-bottom: 8px;
          }
        }
        .jp {
          font-size: 2.5rem;
          font-weight: 500;
          @include media (sp) {
            font-size: 2rem;
          }
        }
      }
      &__guide {
        font-size: 1.8rem;
        @include media (sp) {
          font-size: 1.6rem;
          margin-top: 20px;
        }
      }
    }
  }

  &__form {
    margin: 70px 0 140px;
    @include media (sp) {
      margin: 30px 0 60px;
    }
  }
}


.l-contact__form form {
  table {
    background-color: #F0F8F6;
    width: 100%;
    
    tr {
      &:not(:last-child){
        border-bottom: 2px solid #fff;
      }
      &:last-child {
        th {
          vertical-align: top;
        }
      }
      
      th {
        padding: 32px 0 32px 54px;
        width: 30%;
        span {
          font-size: 2.2rem;
          font-weight: 500;
          @include media (tab) {
            font-size: 1.6rem;
          }
        }
      }
  
      td {
        width: 70%;
        padding: 32px 54px 32px 14px;
        input, textarea {
          border: 1px solid #4BB98E;
          border-radius: 0;
          padding: 0.5em;
        }
        input {
          height: 50px;
          width: 70%;
          @include media (tab) {
            width: 100%;
            height: 40px;
          }
        }
        textarea {
          width: 100%;
        }
      }
  
      .noticeTxt {
        font-size: 1.2rem;
        margin-top: 10px;
        @include media (tab) {
          font-size: 1.1rem;
          margin-top: 8px;
        }
      }
  
    }
    @include media (tab) {
      tr {
        th, td {
          display: block;
          width: 100%;
        }
        th {
          padding: 10px 20px;
        }
        td {
          padding: 0 20px 10px; 
        }
      }
      
    }
    
  }

  .formBtn{
    text-align: center;
    margin: 80px auto 0;
    width: 60%;
    color: #fff;
    border-radius: 5px;
    background-color: #4BB98E;
    transition: 0.3s ease 0s;
    @include media (sp) {
      margin-top: 40px;
    }
    input {
      border: 0;
      background-color: transparent;
      color: #fff;
      font-size: 1.7rem;
      font-weight: 600;
      width: 100%;
      height: 100%;
      padding: 24px;
      cursor: pointer;
      @include media (sp) {
        font-size: 1.6rem;
        padding: 16px;
      }
    }
    
    &:hover {
      opacity: .7;
    }
    // contact form 7 調整用
    .wpcf7-spinner {
      display: none;
    }
  }

}

// info page (お知らせ一覧)
.l-infoList {
  margin-bottom: 150px;
  @include media (1120px) {
    padding: 0 12px;
  }
  @include media (sp) {
    margin-bottom: 80px;
  }
  &__title {
    .en {
      font-size: 1.7rem;
      font-family: $f_baskervill;
      @include media (sp) {
        font-size: 1.4rem;
      }
    }
    .jp {
      font-size: 3.5rem;
      font-family: $f_serif;
      font-weight: 600;
      @include media (sp) {
        font-size: 2.4rem;
      }
    }
  }
  &__wrap {
    margin-top: 65px; 
    padding: 0 6px;
    @include media (sp) {
      margin-top: 30px;
    }
   }
}

.infoList {
  &__list {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 46px;
    @include media (tab) {
      gap: 32px;
      flex-flow: nowrap column;
    }
  }

  &__item {
    width: calc(50% - 23px);
    padding: 24px 20px 20px 24px;
    border: .5px solid #707070;
    outline: 1px solid #707070;
    outline-offset: 4px;
    transition: 0.3s ease 0s;

    @include media (tab) {
      width: 100%;
      padding: 12px 10px 10px 12px;
    }


    &:hover {
      border: .5px solid #4BB98E;
      outline: 1px solid #4BB98E;
    }
    &:last-child {
      margin-right: auto;
    }

    &-date {
      font-size: 1.4rem;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 12px 0 22px;
      &::after {
        content: '';
        display: inline-block;
        width: 40px;
        height: 17px;
        margin: 8px 0 0 40px;
        @include media (sp) {
          width: 32px;
          height: 16px;
        }
      }

      &.newIcon {
        &::after {
          content: 'NEW';
          display: inline-block;
          width: 40px;
          height: 17px;
          font-size: 1.1rem;
          background-color: #4BB98E;
          color: #fff;
          font-weight: 500;
          border-radius: 2px;
          text-align: center;
          line-height: 1.5;
          padding: 0 8px;
          margin: 8px 0 0 40px;
          @include media (sp) {
            font-size: 1rem;
            width: 38px;
            height: 16px;
            }
        }
      }
    }

    &-btn {
      background-color: #707070;
      width: 133px;
      height: 32px;
      margin-left: auto;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/images/icon_arrow_right.svg);
        background-repeat: no-repeat;
        background-size: 14px 4px;
        background-position: right 16px center;
        background-color: #fff;
        border-radius: 7px;
        border: solid 1px #707070;
        font-family: $f_serif;
        font-size: 1.2rem;
        span {
          margin-right: .4rem;
        }
      }
    }
  }
}

// ページャ共通
.pager {
  text-align: center;
  margin-top: 100px;
  @include media (sp) {
    margin-top: 50px
  }
  a, p {
    display: inline-block;
    width: 30px;
    height: 30px;
    @include media (sp) {
      width: 26px;
      height: 26px;
    }
  }
  a {
    margin: 0 8px;
    &.current {
      background-color: #4BB98E;
      color: #fff;
    }
    &.prev {
      width: 6px;
      height: 10px;
      background-image: url(../../assets/images/ico_pager-left.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.next {
      width: 6px;
      height: 10px;
      background-image: url(../../assets/images/ico_pager-right.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

// info detail page (お知らせ詳細)
.l-infoDetail {
  max-width: 1106px;
  width: 100%;
  margin: 0 auto 150px;
  @include media (1024px) {
    width: calc(100% - 26px);
  }
  @include media (sp) {
    margin-bottom: 75px;
  }

  &__top {
    text-align: center;
    border: 1px solid #707070;
    outline: .5px solid #707070;
    outline-offset: -8px;
    line-height: 1;
    padding: 40px;
    @include media (sp) {
      padding: 20px;
    }
    .en {
      font-family: $f_baskervill;
      font-size: 1.7rem;
      margin-bottom: 16px;
      @include media (sp) {
        font-size: 1.4rem;
        margin-bottom: 8px;
      }
    }
    .jp {
      font-family: $f_serif;
      font-size: 3.5rem;
      font-weight: 600;
      @include media (sp) {
        font-size: 2rem;
      }
    }
  }

  &__wrap {
    margin-top: 60px;
    @include media (sp) {
      margin-top: 30px;
    }
    article {
      h1 {
        font-size: 2.2rem;
        border-top: solid 2px #4BB98E;
        border-bottom: solid 2px #4BB98E;
        margin: 4px 0;
        position: relative;
        padding: 14px 0;
        color: #4BB98E;
        font-weight: 700;
        @include media (sp) {
          font-size: 1.8rem;
        }
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          border-bottom: solid 1px#4BB98E;
          position: absolute;
          left: 0px;
          top: -6px;
        }
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          border-bottom: solid 1px#4BB98E;
          position: absolute;
          left: 0px;
          bottom: -6px;
        }
      }

      h2 {
        font-size: 2rem;
        font-weight: 700;
        color: #4BB98E;
        border-bottom: solid 2px #4BB98E;
        padding: 14px 0;
        @include media (sp) {
          font-size: 1.6rem;
          padding: 10px 0;
        }
      }

      h3 {
        font-weight: 700;
        padding: 14px 0;
        @include media (sp) {
          font-size: 1.4rem;
          padding: 10px 0;
        }
      }
      
      p {
        margin: 10px 0;
      }

      .btn {
        text-align: center;
        height: 100%;
        margin-top: 72px;
        @include media (sp) {
          margin-top: 36px;
        }
        span {
          color: #4BB98E;
          border: solid 1px #4BB98E;
          padding: 16px;
          display: inline-block;
          width: calc(100% - 60px);
          max-width: 470px;
          @include media (sp) {
            padding: 12px;
          }
        }
      }
    }
  }
}

// contact thanks page
.thanks {
  width: calc(100% - 20px);
  margin: 80px auto 160px;
  @include media (sp) {
    margin: 40px auto 80px;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    @include media (sp) {
      font-size: 2.4rem;
    }
  }

  a {
    color: #4BB98E;
    font-weight: 700;
  }
}