@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@keyframes headerSlideDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea,
select {
  margin: 0;
  font-size: 16px;
}

button {
  padding: 0;
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset, img {
  border: 0;
}

img {
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: inherit;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

sup, sub {
  font-size: 63%;
}

sup {
  font-size: 75.5%;
  vertical-align: top;
}

sub {
  font-size: 75.5%;
  vertical-align: bottom;
}

hr {
  border: none;
  border-bottom: solid 1px #D9D9D9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  min-height: 100%;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
  line-height: 1.75;
  color: #333;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
}
body:lang(en) {
  font-family: "Vidaloka", "Noto Serif JP", serif;
}
@media (max-width: 640px) {
  body {
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease 0s;
}
a[href^=tel] {
  color: inherit;
}
@media (min-width: 769px) {
  a img {
    transition: 0.3s ease 0s;
  }
  a[href]:hover {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}

i {
  font-style: normal;
}

@media (max-width: 640px) {
  .only-pc {
    display: none !important;
  }
}

@media (min-width: 641px) {
  .only-sp {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .only-tab-min {
    display: none !important;
  }
}

.only-sp-land {
  display: none !important;
}
@media (max-width: 360px) {
  .only-sp-land {
    display: block !important;
  }
}

@media (min-width: 321px) {
  .only-ss {
    display: none !important;
  }
}

.is-required::after {
  content: "※";
  margin: 0 0.5em;
  color: #ED5454;
}

.cf::after {
  display: block;
  clear: both;
  content: "";
}

input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=date] {
  height: 32px;
  padding: 0 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}
input[type=text][size="60"], input[type=password][size="60"], input[type=email][size="60"], input[type=tel][size="60"], input[type=search][size="60"], input[type=date][size="60"] {
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 240px;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.l-header {
  position: fixed;
  width: 1132px;
  max-width: 100%;
  margin: auto;
  padding: 0 13px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
}
.l-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 26px 0 30px;
  border-bottom: solid 1px #C3C1C1;
}
.l-header--top {
  width: 1306px;
}
.l-header--top .l-header__inner {
  border-bottom: none;
  padding: 26px 37px 20px;
}
.l-header__logo {
  display: flex;
  align-items: center;
}
.l-header__logo a {
  display: block;
  width: 211px;
}
.l-header__logo a img {
  height: 50px;
}
.l-header__copy {
  position: relative;
  margin-left: 21px;
  padding-left: 22px;
  font-size: 1.6rem;
  line-height: 1.5625;
}
.l-header__copy span::before,
.l-header__copy span::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  width: 1px;
  height: 45px;
  background-color: #000;
}
.l-header__copy span::before {
  left: 0;
}
.l-header__copy span::after {
  left: 5px;
}
@media (max-width: 768px) {
  .l-header__inner {
    padding: 12px 0;
  }
  .l-header--top .l-header__inner {
    margin: 0;
    padding: 13px 37px;
  }
  .l-header__logo a {
    width: 104px;
  }
  .l-header__logo a img {
    height: 24px;
  }
  .l-header__copy {
    margin-left: 8px;
    padding-left: 8px;
    font-size: 1rem;
    line-height: 1.5;
    top: -1px;
  }
  .l-header__copy span::before,
.l-header__copy span::after {
    top: 3px;
    height: 25px;
  }
  .l-header__copy span::after {
    left: 3px;
  }
}
@media (max-width: 640px) {
  .l-header--top .l-header__inner {
    padding: 13px 0;
  }
}

.l-navi {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.l-navi__spButton {
  display: none;
}
.l-navi__sp {
  display: none;
}
.l-navi__item {
  text-align: center;
  padding-bottom: 12px;
}
.l-navi__item:hover, .l-navi__item.current {
  background-image: url(../../assets/images/icon_square_green.svg);
  background-position: center bottom;
  background-size: 7px 7px;
  background-repeat: no-repeat;
}
.l-navi__item span {
  display: block;
  line-height: 1;
}
.l-navi__item span.jp {
  font-size: 1.6rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  padding: 0 25px 2px;
  font-weight: 500;
}
.l-navi__item span.en {
  padding: 0 5px;
  font-size: 1.1rem;
  font-family: "Libre Baskerville", serif;
}
.l-navi__item--icon {
  text-align: center;
  padding: 0 15px 12px 0;
}
.l-navi__item--icon:hover {
  opacity: 0.7;
}
@media (max-width: 768px) {
  .l-navi__item--icon {
    padding: 0 10px 12px 10px;
  }
}
.l-navi__icon {
  display: flex;
  margin: 0 20px 0 45px;
}
@media (max-width: 768px) {
  .l-navi__icon {
    margin: 0 0 8% 0;
  }
}
.l-navi__sns {
  display: flex;
  align-items: center;
  margin: 0 35px 0 45px;
}
.l-navi__snsItem {
  width: 25px;
  height: 25px;
}
.l-navi__snsItem + .l-navi__snsItem {
  margin-left: 15px;
}
.l-navi__langSwitch {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  position: relative;
}
@media (max-width: 768px) {
  .l-navi__langSwitch {
    margin-bottom: 30px;
  }
}
.l-navi__langSwitch .lang {
  display: flex;
  align-items: center;
}
.l-navi__langSwitch .lang img {
  max-height: 12px;
  width: auto;
  border: solid 1px #DDD;
  margin-right: 6px;
}
.l-navi__language {
  padding-bottom: 16px;
  border-bottom: solid 1px #707070;
  background-image: url(../../assets/images/icon_angle_bottom_green.svg);
  background-position: center bottom 8px;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.l-navi__language:hover {
  opacity: 0.7;
}
.l-navi__langList {
  padding: 10px;
  position: absolute;
  top: 100%;
  left: -24%;
  background-color: #fff;
  line-height: 1.5;
}
.l-navi__langList li:not(:first-child) {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .l-navi__langList li:not(:first-child) {
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .l-navi__langList {
    position: static;
    padding: 16px 0;
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .l-navi {
    position: fixed;
    top: 52px;
    right: -100vw;
    width: 100vw;
    height: calc(100vh - 52px);
    flex-direction: column;
    align-items: center;
    padding-top: 16%;
    background-color: white;
    z-index: 10;
    transition: right ease-in-out 0.8s;
    background-image: url(../../assets/images/bg_map_japan.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
  }
  .l-navi.active {
    right: 0;
  }
  .l-navi__item {
    margin-bottom: 8%;
  }
  .l-navi__item + .l-navi__sp {
    margin-top: 100px;
  }
  .l-navi__spButton {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    cursor: pointer;
    z-index: 20;
  }
  .l-navi__spButton span {
    position: absolute;
    right: 0;
    content: "";
    display: block;
    height: 1px;
    background-color: #707070;
  }
  .l-navi__spButton span:nth-of-type(1) {
    width: 32px;
  }
  .l-navi__spButton span:nth-of-type(2) {
    width: 27px;
    top: 7px;
  }
  .l-navi__spButton span:nth-of-type(3) {
    width: 22px;
    top: 14px;
  }
  .l-navi__spButton.active span:first-child {
    transform: rotate(-30deg);
    width: 36px;
    top: 7px;
  }
  .l-navi__spButton.active span:nth-of-type(2) {
    display: none;
  }
  .l-navi__spButton.active span:last-child {
    width: 36px;
    top: 7px;
    transform: rotate(30deg);
  }
  .l-navi__sp {
    display: block;
    font-size: 1.3rem;
    line-height: 2;
  }
  .l-navi__sp.copyright {
    position: absolute;
    bottom: 2%;
    font-size: 0.9rem;
  }
}

.l-breadcrumbs {
  width: 1132px;
  max-width: 100%;
  padding: 21px 13px 40px;
  margin: auto;
}
.l-breadcrumbs ul {
  display: flex;
  align-items: center;
}
.l-breadcrumbs li {
  position: relative;
  padding-right: 10px;
  line-height: 1;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.2rem;
}
.l-breadcrumbs li + li {
  padding-left: 20px;
}
.l-breadcrumbs li + li::before {
  content: ">";
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .l-breadcrumbs {
    padding: 8px 13px 15px;
  }
  .l-breadcrumbs li {
    padding-right: 0;
    font-size: 8px;
  }
  .l-breadcrumbs li + li {
    padding-left: 12px;
  }
}

.l-main {
  margin: auto;
  padding: 97px 0 0;
}
@media (max-width: 1365px) {
  .l-main {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .l-main {
    padding: 55px 0 0;
  }
}

.l-productLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.l-productLine::before, .l-productLine::after,
.l-productLine span::before,
.l-productLine span::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #707070;
}
.l-productLine::before, .l-productLine::after {
  height: 1px;
}
.l-productLine::before {
  width: 115%;
  top: 140px;
  left: -153px;
}
.l-productLine::after {
  width: 80%;
  top: 150px;
  right: -153px;
}
.l-productLine span::before,
.l-productLine span::after {
  width: 1px;
  height: 70px;
}
.l-productLine span::before {
  top: 100px;
  left: 80%;
}
.l-productLine span::after {
  top: 120px;
  left: calc(80% + 8px);
}

.l-hero {
  width: 1306px;
  max-width: 100%;
  padding: 0 50px;
  margin: auto;
}
.l-hero__figure {
  position: relative;
}
.l-hero__img img {
  width: 100%;
}
.l-hero__caption {
  position: absolute;
  top: 50%;
  right: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  padding: 10px 3px 0;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.35em;
  writing-mode: vertical-rl;
  line-height: 1;
  transform: translateY(-50%);
}
.l-hero__caption span {
  display: block;
  width: 32px;
  white-space: nowrap;
  line-height: 1;
}
.l-hero__caption span span.dot {
  display: inline;
  writing-mode: horizontal-tb;
  height: 32px;
  margin: -22px 0 10px;
}
@media (max-width: 640px) {
  .l-hero {
    padding: 0 13px;
  }
}

.hero__slider {
  position: relative;
  z-index: 2;
}
.hero__slider .slick-dots {
  position: absolute;
  right: 13px;
  bottom: 20px;
}
.hero__slider .slick-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.hero__slider .slick-dots li::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.hero__slider .slick-dots li.slick-active {
  background-image: url(../../assets/images/icon_square_green.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center center;
}
.hero__slider .slick-dots li.slick-active::before {
  display: none;
}
.hero__slider .slick-dots button {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: 100%;
  z-index: 1;
}
@media (max-width: 640px) {
  .hero__slider .slick-dots {
    display: flex;
    right: 13px;
    bottom: -23px;
  }
}

.l-topPage {
  width: 1080px;
  max-width: 100%;
  margin: auto;
  padding: 0 10px;
}
.l-topPage__top {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 125px 70px;
}
.l-topPage__content {
  position: relative;
}
.l-topPage__title {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  background-repeat: no-repeat;
}
.l-topPage__title::before {
  position: absolute;
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.m--top .l-topPage__title {
  width: 118px;
  margin: 36px 30px 0 0;
}
.m--top .l-topPage__title::before {
  top: -160px;
  left: -34px;
  width: 21px;
  height: 250px;
  background-image: url(../../assets/images/line_featured.svg);
  mask-image: url(../../assets/images/line_featured.svg);
  -webkit-mask-image: url(../../assets/images/line_featured.svg);
  background-color: #c3c1c1;
}
.m--category .l-topPage__title {
  height: 204px;
  margin-bottom: 30px;
}
.m--category .l-topPage__title::before {
  bottom: 0;
  left: 0;
  width: 103px;
  height: 21px;
  background-image: url(../../assets/images/line_title.svg);
  mask-image: url(../../assets/images/line_title.svg);
  -webkit-mask-image: url(../../assets/images/line_title.svg);
  background-color: #c3c1c1;
}
.m--admin .l-topPage__title {
  width: 67px;
  margin-top: 190px;
}
.l-topPage__title .en {
  font-family: "Libre Baskerville", serif;
  font-size: 1.7rem;
  writing-mode: vertical-rl;
}
.m--top .l-topPage__title .en {
  font-size: 1.6rem;
  margin-left: 7.2px;
}
.m--admin .l-topPage__title .en {
  font-size: 1.4rem;
}
.l-topPage__title .jp {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 3.5rem;
  font-weight: 600;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  letter-spacing: 0.3em;
}
.m--top .l-topPage__title .jp {
  font-size: 2.6rem;
  line-height: 1.45;
}
.m--admin .l-topPage__title .jp {
  font-size: 2.5rem;
}
.l-topPage__title.icon-marine {
  background-image: url(../../assets/images/icon_marine.svg);
  background-size: 125px 85px;
  background-position: 0 20px;
}
.l-topPage__title.icon-livestock {
  background-image: url(../../assets/images/icon_livestock.svg);
  background-size: 87px 76px;
  background-position: 100px 40px;
}
.l-topPage__title.icon-rice {
  background-image: url(../../assets/images/icon_rice.svg);
  background-size: 94px 91px;
  background-position: 0 20px;
}
.l-topPage__title.icon-fruit {
  background-image: url(../../assets/images/icon_fruit.svg);
  background-size: 104px 104px;
  background-position: 100px 20px;
}
.l-topPage__overview {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.4rem;
  line-height: 1.78;
  white-space: nowrap;
}
.m--top .l-topPage__overview {
  margin: 64px 0 0 0;
  font-size: 1.6rem;
  line-height: 2;
}
.m--top .l-topPage__overview p + p {
  padding-top: 1em;
}
.m--category .l-topPage__overview {
  margin-bottom: 30px;
}
.m--top .l-topPage__figure {
  display: flex;
  align-items: center;
}
.m--top .l-topPage__figure img {
  width: 392px;
  height: auto;
}
.l-topPage__category {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.l-topPage__category:nth-of-type(even) {
  flex-direction: row-reverse;
  padding-right: 70px;
}
.l-topPage__category:nth-of-type(even) .l-topPage__title {
  justify-content: flex-start;
}
.l-topPage__category:nth-of-type(even) .l-topPage__figure {
  margin-right: 20px;
}
.l-topPage__category:nth-of-type(odd) {
  padding-left: 70px;
}
.l-topPage__category:nth-of-type(odd) .l-topPage__title {
  justify-content: flex-end;
}
.l-topPage__category:nth-of-type(odd) .l-topPage__figure {
  margin-left: 20px;
}
.l-topPage__more {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #707070;
  width: 184px;
  height: 43px;
}
.l-topPage__more a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 14px;
  background-image: url(../../assets/images/icon_arrow_right.svg);
  background-repeat: no-repeat;
  background-size: 20px 3px;
  background-position: right 20px center;
  background-color: #fff;
  border-radius: 7px;
  border: solid 1px #707070;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.4rem;
}
.l-topPage__admin {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  padding: 100px 42px 100px 0;
}
.l-topPage__adminLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.l-topPage__adminLine::before, .l-topPage__adminLine::after,
.l-topPage__adminLine span::before,
.l-topPage__adminLine span::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #c1c1c1;
}
.l-topPage__adminLine::before, .l-topPage__adminLine::after {
  height: 1px;
}
.l-topPage__adminLine::before {
  width: 115%;
  top: 140px;
  left: -153px;
}
.l-topPage__adminLine::after {
  width: 80%;
  top: 150px;
  right: -153px;
}
.l-topPage__adminLine span::before,
.l-topPage__adminLine span::after {
  width: 1px;
  height: 70px;
}
.l-topPage__adminLine span::before {
  top: 100px;
  left: 80%;
}
.l-topPage__adminLine span::after {
  top: 120px;
  left: calc(80% + 8px);
}
.l-topPage__adminOverview {
  margin: 230px 44px 0 60px;
  width: 201px;
}
.l-topPage__adminOverview img {
  width: 201px;
  max-width: none;
  height: 270px;
}
.l-topPage__adminPictures {
  display: flex;
  width: calc(100% - 270px);
}
.l-topPage__adminFigure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70.245%;
}
.l-topPage__adminFigure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.l-topPage__adminFigure:first-of-type {
  margin-right: 35px;
}
.l-topPage__adminFigure:nth-of-type(2) {
  margin-top: 140px;
}
.l-topPage__adminFigure.only-sp img:nth-of-type(1) {
  opacity: 1;
  transition: all 0.8s ease-in-out;
  z-index: 2;
}
.l-topPage__adminFigure.only-sp:hover img:nth-of-type(1) {
  opacity: 0;
}
.l-topPage__adminFigure.only-sp:hover::before {
  background-color: #C3C3C3;
}
.l-topPage__adminFigure.only-sp:hover::after {
  background-color: #4BB98E;
}
.l-topPage__adminFigure.only-sp::before, .l-topPage__adminFigure.only-sp::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -23px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.l-topPage__adminFigure.only-sp::before {
  background-color: #4BB98E;
  right: 16px;
  transition: all 0.8s ease-in-out;
}
.l-topPage__adminFigure.only-sp::after {
  background-color: #C3C3C3;
  right: 0;
  transition: all 0.8s ease-in-out;
}
@media (max-width: 1365px) {
  .m--top .l-topPage {
    margin: 36px 0 0 0;
  }
  .l-topPage__admin {
    overflow: hidden;
  }
  .l-topPage__adminLine::before {
    width: 90%;
  }
  .l-topPage__adminLine::after {
    width: 60%;
  }
  .l-topPage__adminLine::before {
    left: -10px;
  }
  .l-topPage__adminLine::after {
    right: -10px;
  }
}
@media (max-width: 1200px) {
  .l-topPage__more {
    position: static;
  }
}
@media (max-width: 640px) {
  .l-topPage {
    padding: 0 13px;
  }
  .l-topPage__top {
    display: block;
    padding: 12% 0;
  }
  .l-topPage__top.m--top {
    text-align: center;
  }
  .l-topPage__content {
    padding: 0 5.33%;
  }
  .m--top .l-topPage__title {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 0;
    margin: 0 0 5.8% 0;
    padding-top: 79.083%;
    text-align: left;
    background-image: url(../../assets/images/img_featured_sp@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right top;
  }
  .m--top .l-topPage__title::before {
    display: none;
  }
  .m--category .l-topPage__title {
    width: 74px;
    height: auto;
    margin: 0;
    padding-top: 70px;
    float: right;
  }
  .m--category .l-topPage__title::before {
    display: none;
  }
  .m--admin .l-topPage__title {
    position: absolute;
    bottom: 240px;
    right: 9.33%;
    width: 53px;
    height: 103px;
    margin: 0;
    padding: 10px 0;
    background-color: #fff;
    z-index: 5;
  }
  .l-topPage__title .en {
    font-size: 1.2rem;
  }
  .m--top .l-topPage__title .en {
    position: absolute;
    top: 0;
    left: 87px;
    font-size: 1.4rem;
    margin-left: 0;
  }
  .m--admin .m--top .l-topPage__title .en {
    font-size: 1.2rem;
  }
  .l-topPage__title .jp {
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .m--top .l-topPage__title .jp {
    position: absolute;
    top: 0;
    left: 27px;
    font-size: 2rem;
    line-height: 1.2;
  }
  .m--admin .l-topPage__title .jp {
    font-size: 1.8rem;
  }
  .l-topPage__title.icon-marine {
    background-size: 74px 50px;
    background-position: right top;
  }
  .l-topPage__title.icon-livestock {
    background-size: 53px 55px;
    background-position: right top;
  }
  .l-topPage__title.icon-rice {
    background-size: 52px 50px;
    background-position: right top;
  }
  .l-topPage__title.icon-fruit {
    background-size: 53px 53px;
    background-position: right top;
  }
  .l-topPage__overview {
    min-height: 68px;
    font-size: 1.2rem;
    line-height: 1.9;
  }
  .m--top .l-topPage__overview {
    display: inline-block;
    margin: 0;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.83;
  }
  .m--category .l-topPage__overview {
    margin: 16.39% 0 9.18%;
  }
  .m--top .l-topPage__figure {
    display: none;
  }
  .l-topPage__category {
    margin-bottom: 0;
  }
  .l-topPage__category:nth-of-type(even) {
    flex-direction: column-reverse;
    padding-right: 0;
  }
  .l-topPage__category:nth-of-type(even) .l-topPage__figure {
    margin: 0 0 5.33% 0;
  }
  .l-topPage__category:nth-of-type(odd) {
    flex-direction: column-reverse;
    padding-left: 0;
  }
  .l-topPage__category:nth-of-type(odd) .l-topPage__title {
    justify-content: flex-start;
  }
  .l-topPage__category:nth-of-type(odd) .l-topPage__figure {
    margin: 0 0 5.33% 0;
  }
  .l-topPage__more {
    width: 126px;
    height: 27px;
    margin-bottom: calc(17.33% + 10px);
  }
  .l-topPage__more a {
    margin-right: 0;
    font-size: 0.9rem;
    background-image: url(../../assets/images/icon_see_more.svg);
    background-size: 12px 3px;
  }
  .l-topPage__more a span {
    margin-left: -10px;
  }
  .l-topPage__admin {
    position: relative;
    flex-direction: column-reverse;
    width: calc(100% + 26px);
    margin: 0 0 0 -13px;
    padding: 26.66% 0 0;
    background-image: url(../../assets/images/line_admin_sp.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
  }
  .l-topPage__adminLine {
    display: none;
  }
  .l-topPage__adminOverview {
    margin: 0;
    padding: 0 110px 70px 0;
    width: 100%;
    text-align: right;
  }
  .l-topPage__adminOverview img {
    width: 160px;
    height: auto;
  }
  .l-topPage__adminPictures {
    width: 100%;
    margin-bottom: 28px;
  }
  .l-topPage__adminFigure.only-sp {
    padding-top: 89.33%;
  }
  .l-topPage__adminFigure.only-sp::before, .l-topPage__adminFigure.only-sp::after {
    bottom: -18px;
  }
  .l-topPage__adminFigure.only-sp::before {
    right: auto;
    left: 33px;
  }
  .l-topPage__adminFigure.only-sp::after {
    right: auto;
    left: 17px;
  }
}
@media (max-width: 320px) {
  .m--top .l-topPage__overview {
    font-size: 1.1rem;
  }
}

.l-archive {
  width: 1106px;
  max-width: 100%;
  margin: auto;
}
.l-archive__hero {
  position: relative;
  padding: 0 34px 60px;
}
.l-archive__heroFigure {
  width: 100%;
}
.l-archive__heroCaption {
  position: absolute;
  top: 50px;
  left: 148px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}
.l-archive__heroCaption span {
  margin-right: 1em;
  padding: 5px 3px;
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  line-height: 1;
  letter-spacing: 0.05em;
  vertical-align: top;
  writing-mode: vertical-rl;
  background-color: rgba(255, 255, 255, 0.9);
}
.l-archive__heroCaption span strong {
  display: inline-block;
  padding-bottom: 3px;
}
.l-archive__heroCaption span strong::before, .l-archive__heroCaption span strong::after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 7px;
  background-repeat: no-repeat;
  background-size: 5px 4px;
}
.l-archive__heroCaption span strong::before {
  background-image: url(../../assets/images/icon_double_quotation_01.svg);
  background-position: right top;
}
.l-archive__heroCaption span strong::after {
  background-image: url(../../assets/images/icon_double_quotation_02.svg);
  background-position: right center;
}
.l-archive__explanation {
  display: flex;
  justify-content: space-between;
  padding: 0 34px;
}
.l-archive__explanationText {
  width: calc(100% - 240px);
  font-size: 1.3rem;
  line-height: 2.3;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
}
.l-archive__title {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  background-repeat: no-repeat;
}
.l-archive__title::before {
  position: absolute;
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}
.l-archive__title .en {
  font-family: "Libre Baskerville", serif;
  font-size: 1.6rem;
  writing-mode: vertical-rl;
}
.l-archive__title .jp {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 3rem;
  font-weight: 600;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  letter-spacing: 0.3em;
}
.l-archive__title.icon-marine {
  width: 222px;
  background-image: url(../../assets/images/icon_marine.svg);
  background-size: 125px 85px;
  background-position: 0 20px;
}
.l-archive__title.icon-livestock {
  width: 222px;
  background-image: url(../../assets/images/icon_livestock.svg);
  background-size: 87px 76px;
  background-position: 40px 20px;
}
.l-archive__title.icon-rice {
  width: 222px;
  background-image: url(../../assets/images/icon_rice.svg);
  background-size: 94px 91px;
  background-position: 40px 20px;
}
.l-archive__productLine {
  position: relative;
  width: 100%;
  height: 90px;
  margin: 46px 0;
}
.l-archive__productLine::before, .l-archive__productLine::after,
.l-archive__productLine span::before,
.l-archive__productLine span::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #c1c1c1;
}
.l-archive__productLine::before, .l-archive__productLine::after {
  height: 1px;
}
.l-archive__productLine::before {
  width: 87%;
  top: 40px;
  left: 34px;
}
.l-archive__productLine::after {
  width: 80%;
  top: 48px;
  right: 34px;
}
.l-archive__productLine.m--product::before {
  width: 90%;
  left: 0;
}
.l-archive__productLine.m--product::after {
  width: 90%;
  right: 0;
}
.l-archive__productLine span::before,
.l-archive__productLine span::after {
  width: 1px;
  height: 70px;
}
.l-archive__productLine span::before {
  top: 0;
  left: 80%;
}
.l-archive__productLine span::after {
  top: 20px;
  left: calc(80% - 8px);
}
.l-archive__product {
  width: 100%;
  padding: 0 34px;
}
.l-archive__productTitle {
  margin-bottom: 50px;
}
.l-archive__productTitle .en {
  position: relative;
  margin-bottom: 35px;
  font-family: "Libre Baskerville", serif;
  font-size: 1.6rem;
  line-height: 1;
}
.l-archive__productTitle .en::after {
  position: absolute;
  bottom: -14px;
  left: 0;
  content: "";
  width: 35px;
  height: 1px;
  background-color: #707070;
}
.l-archive__productTitle .jp {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 1000px) {
  .l-archive p + p {
    margin-top: 1em;
  }
  .l-archive br.only-pc {
    display: none;
  }
}
@media (max-width: 640px) {
  .l-archive__hero {
    position: relative;
    padding: 0 13px;
  }
  .l-archive__heroCaption {
    display: none;
  }
  .l-archive__explanation {
    flex-direction: column-reverse;
    padding: 0 13px;
  }
  .l-archive__explanationText {
    width: 100%;
    font-size: 1.2rem;
    line-height: 2;
  }
  .l-archive__title {
    padding: 10px 5px;
  }
  .l-archive__title .en {
    font-size: 1.2rem;
  }
  .l-archive__title .jp {
    font-size: 2.5rem;
    letter-spacing: 0.2em;
    line-height: 1.5;
  }
  .l-archive__title.icon-marine, .l-archive__title.icon-livestock, .l-archive__title.icon-rice {
    position: relative;
    top: -30px;
    margin: 0 30px -30px auto;
    width: 72px;
    height: auto;
    background-color: #fff;
    background-image: none;
  }
  .l-archive__title.icon-marine::after, .l-archive__title.icon-livestock::after, .l-archive__title.icon-rice::after {
    position: absolute;
    bottom: 26px;
    content: "";
    display: block;
    width: 73px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .l-archive__title.icon-marine::after {
    right: 82px;
    background-image: url(../../assets/images/icon_marine.svg);
  }
  .l-archive__title.icon-livestock::after {
    right: 65px;
    background-image: url(../../assets/images/icon_livestock.svg);
  }
  .l-archive__title.icon-rice::after {
    right: 60px;
    background-image: url(../../assets/images/icon_rice.svg);
  }
  .l-archive__productLine {
    display: none;
  }
  .l-archive__product {
    margin: 36px 0 8%;
    padding: 22.13% 5.33% 0;
    background-image: url(../../assets/images/line_admin_sp.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
  }
  .l-archive__productTitle .en {
    margin-bottom: 24px;
    font-size: 1.4rem;
  }
  .l-archive__productTitle .en::after {
    bottom: -9px;
    width: 20px;
  }
  .l-archive__productTitle .jp {
    font-size: 1.8rem;
  }
}

.l-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 0 13px;
}
.l-column__item {
  width: 33.33%;
  margin: 0 0 70px;
  padding: 0 1.85%;
}
.l-column__item:hover .l-column__more p {
  opacity: 0.8;
}
.l-column__item img:nth-of-type(1) {
  z-index: 2;
}
.l-column__item.twoTmb a:hover img:nth-of-type(1) {
  opacity: 0;
}
.l-column__item.twoTmb a:hover .l-column__title::before {
  background-color: #C3C3C3;
}
.l-column__item.twoTmb a:hover .l-column__title::after {
  background-color: #4BB98E;
}
.l-column__thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  overflow: hidden;
  margin-bottom: 20px;
}
.l-column__thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  max-width: initial;
  transform: translate(-50%, -50%);
}
.l-column__title {
  margin-bottom: 13px;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.6rem;
  font-weight: 900;
  position: relative;
}
.twoTmb .l-column__title::before, .twoTmb .l-column__title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 32px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.twoTmb .l-column__title::before {
  background-color: #4BB98E;
  right: 16px;
}
.twoTmb .l-column__title::after {
  background-color: #C3C3C3;
  right: 0;
}
.l-column__description {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.83;
}
.l-column__more {
  background-color: #707070;
  width: 100%;
  height: 36px;
}
.l-column__more p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/icon_see_more.svg);
  background-repeat: no-repeat;
  background-size: 11px 4px;
  background-position: left calc(50% + 40px) center;
  background-color: #fff;
  border-radius: 7px;
  border: solid 1px #707070;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.2rem;
  margin-right: 14px;
}
@media (max-width: 640px) {
  .l-column {
    margin-bottom: 7%;
    padding: 0;
  }
  .l-column__item {
    width: 48%;
    margin: 0 0 13%;
    padding: 0;
  }
  .l-column__title {
    padding: 0 10px;
  }
  .twoTmb .l-column__title::before, .twoTmb .l-column__title::after {
    width: 6px;
    height: 6px;
    bottom: 36px;
  }
  .twoTmb .l-column__title::before {
    right: 15px;
  }
  .twoTmb .l-column__title::after {
    right: 5px;
  }
  .l-column__description {
    padding: 0 10px;
  }
  .l-column__more {
    width: calc(100% - 20px);
    height: 30px;
    margin: 0 10px;
  }
}

.l-product {
  width: 1178px;
  max-width: 100%;
  margin: auto;
  padding: 0 70px;
}
.l-product__hero {
  width: 1063px;
  max-width: 100%;
  margin: 0 auto 90px;
  padding: 0 13px;
}
.l-product__heroItem {
  display: flex;
}
.l-product__heroFigure {
  width: calc(100% - 340px);
  margin-right: 4.82%;
}
.l-product__heroOutline {
  width: 340px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.l-product__heroName {
  border: solid 1px #707070;
  outline: solid 1px #707070;
  outline-offset: 6px;
  margin: 7px 7px 37px;
  padding: 30px;
  text-align: center;
}
.l-product__heroName .copy {
  font-size: 1.4rem;
}
.l-product__heroName .name {
  font-size: 3rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-weight: 600;
}
.l-product__heroName .en {
  font-size: 1.4rem;
}
.l-product__heroExplanation {
  min-height: 160px;
  margin-bottom: 6px;
  font-size: 1.4rem;
  line-height: 2;
  border-bottom: solid 1px #C3C1C1;
}
.l-product__heroCategory {
  padding-top: 38px;
  border-top: solid 1px #C3C1C1;
  font-size: 1.8rem;
  line-height: 1.9444;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-weight: 500;
}
.l-product__heroCategory dt {
  float: left;
  clear: both;
  width: 4em;
}
.l-product__heroCategory dd {
  padding-left: 4em;
}
.l-product__productLine {
  width: 686px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.l-product__productLine.m--right {
  margin-right: 128px;
  margin-left: auto;
  background-image: url(../../assets/images/line_right.svg);
  mask-image: url(../../assets/images/line_right.svg);
  -webkit-mask-image: url(../../assets/images/line_right.svg);
  background-color: #c3c1c1;
}
.l-product__productLine.m--left {
  margin-left: 128px;
  margin-right: auto;
  background-image: url(../../assets/images/line_left.svg);
  mask-image: url(../../assets/images/line_left.svg);
  -webkit-mask-image: url(../../assets/images/line_left.svg);
  background-color: #c3c1c1;
}
.l-product__voice {
  display: flex;
  width: 1063px;
  max-width: 100%;
  margin: 0 auto 90px;
  padding: 0 13px;
}
.l-product__voiceContent {
  width: 340px;
}
.l-product__voiceTitle {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 0 0 46px 35px;
}
.l-product__voiceTitle .en {
  font-size: 1.4rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.l-product__voiceTitle .jp {
  font-size: 1.8rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.l-product__voiceCopy {
  margin-bottom: 20px;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 2.5rem;
  line-height: 1.68;
}
.l-product__voiceName {
  margin-bottom: 46px;
  padding-right: 50px;
  text-align: right;
}
.l-product__voiceName .occupation {
  font-size: 1.8rem;
}
.l-product__voiceName .name {
  font-size: 2.5rem;
}
.l-product__voiceText {
  font-size: 1.4rem;
  line-height: 2;
}
.l-product__voiceText p + p {
  margin-top: 1em;
}
.l-product__voiceFigure {
  width: calc(100% - 340px);
  margin: 88px 0 0 4.82%;
}
.l-product__recipe {
  display: flex;
  width: 1064px;
  max-width: 100%;
  margin: 0 auto 90px;
  padding: 0 13px;
}
.l-product__recipeFigure {
  width: calc(100% - 340px);
  margin: 90px 4.82% 0 0;
}
.l-product__recipeContent {
  width: 340px;
}
.l-product__recipeTitle {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin: 0 0 46px 26px;
}
.l-product__recipeTitle .en {
  font-size: 1.4rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.l-product__recipeTitle .jp {
  font-size: 1.8rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.l-product__recipeCopy {
  position: relative;
  display: inline-block;
  padding: 0 24px;
  font-size: 1.8rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  line-height: 1.2;
}
.l-product__recipeCopy::before, .l-product__recipeCopy::after {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 18px;
  height: 100%;
  background-image: url(../../assets/images/icon_star.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.l-product__recipeCopy::before {
  left: 0;
}
.l-product__recipeCopy::after {
  right: 0;
}
.l-product__recipeName {
  margin-bottom: 36px;
  font-size: 2.5rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  line-height: 1.52;
}
.l-product__recipeTime {
  position: relative;
  padding-left: 54px;
  margin-bottom: 40px;
  font-size: 3rem;
  line-height: 1.3;
}
.l-product__recipeTime span {
  font-size: 2rem;
}
.l-product__recipeTime::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../assets/images/icon_clock.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.l-product__voiceText {
  font-size: 1.4rem;
  line-height: 2;
}
.l-product__recipeSns {
  margin-top: 42px;
  display: inline-flex;
  align-items: center;
}
.l-product__recipeIcon:first-of-type {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.l-product__recipeIcon:nth-of-type(2) {
  margin-right: 15px;
}
.l-product__material {
  display: flex;
  align-items: flex-start;
  width: 1064px;
  max-width: 100%;
  margin: 0 auto 114px;
  padding: 0 13px;
}
.l-product__materialTitleWrapper {
  width: 187px;
}
.l-product__materialTitle {
  position: relative;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 600;
}
.l-product__materialTitle .title {
  letter-spacing: 0.5em;
  margin-right: -0.5em;
}
.l-product__materialTitle .servings {
  font-size: 1.8rem;
}
.l-product__materialTitle::before, .l-product__materialTitle::after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
.l-product__materialTitle::before {
  bottom: -2px;
}
.l-product__materialTitle::after {
  bottom: -8px;
}
.l-product__materialListWrapper {
  width: calc(100% - 187px);
  padding-top: 50px;
}
.l-product__materialList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 10px 20px;
  background-color: #F5F5F5;
  font-size: 1.6rem;
  font-weight: 500;
}
.l-product__materialList.m--sub {
  color: #B10000;
}
.l-product__recipeSubTitle {
  margin-bottom: 4px;
  font-size: 1.6rem;
  font-weight: 600;
}
.l-product__recipeSubTitle.m--sub {
  color: #B10000;
}
.l-product__process {
  display: flex;
  align-items: flex-start;
  width: 1064px;
  max-width: 100%;
  margin: 0 auto 40px;
  padding: 0 13px;
}
.l-product__processTitleWrapper {
  width: 187px;
}
.l-product__processTitle {
  position: relative;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 600;
}
.l-product__processTitle::before, .l-product__processTitle::after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
.l-product__processTitle::before {
  bottom: -2px;
}
.l-product__processTitle::after {
  bottom: -8px;
}
.l-product__processList {
  width: calc(100% - 187px);
  margin-right: 30px;
  padding-top: 50px;
  counter-reset: processCounter;
}
.l-product__processItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: solid 1px #c3c1c1;
  counter-increment: processCounter;
}
.l-product__processItem::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  content: counter(processCounter);
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 34px;
  text-align: center;
  color: #B10000;
  background-image: url(../../assets/images/bg_number.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.l-product__processText {
  padding: 0 10px 0 60px;
  font-size: 1.4rem;
}
.l-product__processFigure {
  width: 370px;
}
@media (max-width: 768px) {
  .l-product {
    padding: 0;
  }
}
@media (max-width: 640px) {
  .l-product__heroItem {
    flex-direction: column;
  }
  .l-product__heroFigure {
    width: 100%;
    margin: 0 0 9.33%;
  }
  .l-product__heroOutline {
    width: 100%;
    padding: 2.865%;
  }
  .l-product__heroName {
    margin-bottom: 5.73%;
  }
  .l-product__heroName .copy {
    font-size: 1.2rem;
  }
  .l-product__heroName .name {
    font-size: 2.6rem;
  }
  .l-product__heroName .en {
    font-size: 1.3rem;
  }
  .l-product__heroExplanation {
    min-height: initial;
    margin-bottom: 6.59%;
    font-size: 1.2rem;
    border-bottom: none;
    font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  }
  .l-product__heroCategory {
    padding-top: 0;
    font-size: 1.5rem;
    line-height: 2;
    border-top: none;
  }
  .l-product__productLine {
    display: none;
  }
  .l-product__voice {
    flex-direction: column;
    padding: 0 13px;
    background-image: url(../../assets/images/line_right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
  }
  .l-product__voiceContent {
    width: 100%;
    padding: 0 2.865%;
  }
  .l-product__voiceTitle {
    flex-direction: column;
    margin: 0 0 8%;
    padding-top: 24%;
  }
  .l-product__voiceTitle .en {
    writing-mode: unset;
  }
  .l-product__voiceTitle .jp {
    writing-mode: unset;
  }
  .l-product__voiceCopy {
    font-size: 2.2rem;
    line-height: 1.59;
  }
  .l-product__voiceFigure {
    width: auto;
    margin: 0 0 9.33%;
  }
  .l-product__voiceName {
    margin-bottom: 37px;
    padding-right: 18px;
  }
  .l-product__recipe {
    flex-direction: column;
    margin-bottom: 0;
    padding: 0 13px;
    background-image: url(../../assets/images/line_right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
  }
  .l-product__recipeFigure {
    width: 100%;
    margin: 0 0 23px;
  }
  .l-product__recipeCopy {
    margin-bottom: 10px;
  }
  .l-product__recipeName {
    margin-bottom: 17px;
    font-size: 2.2rem;
  }
  .l-product__recipeTime {
    margin-bottom: 32px;
    padding-left: 40px;
    font-size: 2.6rem;
  }
  .l-product__recipeTime span {
    font-size: 1.6rem;
  }
  .l-product__recipeTime::before {
    width: 32px;
    height: 32px;
  }
  .l-product__voiceText {
    font-size: 1.2rem;
  }
  .l-product__recipeContent {
    width: 100%;
    padding: 0 2.865% 17.33%;
  }
  .l-product__recipeTitle {
    flex-direction: column;
    margin: 0 0 8%;
    padding-top: 24%;
  }
  .l-product__recipeTitle .en {
    writing-mode: unset;
  }
  .l-product__recipeTitle .jp {
    writing-mode: unset;
  }
  .l-product__material {
    flex-direction: column;
    width: auto;
    margin: 0 0 40px;
    padding: 0 13px;
  }
  .l-product__materialTitleWrapper {
    width: 100%;
    margin-bottom: 26px;
    text-align: center;
  }
  .l-product__materialTitle {
    font-size: 2rem;
  }
  .l-product__materialTitle .servings {
    font-size: 1.4rem;
  }
  .l-product__materialListWrapper {
    width: 100%;
    padding: 0 2.865%;
  }
  .l-product__materialList {
    padding: 6px 20px;
    font-size: 1.4rem;
  }
  .l-product__recipeSubTitle {
    margin-top: 17px;
  }
  .l-product__process {
    flex-direction: column;
    width: auto;
    padding: 0 13px;
  }
  .l-product__processTitleWrapper {
    width: 100%;
    text-align: center;
  }
  .l-product__processTitle {
    font-size: 2rem;
  }
  .l-product__processList {
    width: 100%;
    margin-right: 0;
  }
  .l-product__processItem {
    flex-direction: column;
    border-bottom: none;
    padding-bottom: 0;
  }
  .l-product__processText {
    padding: 0 10px 30px 55px;
  }
  .l-product__processFigure {
    width: 100%;
  }
  .l-product__processFigure img {
    width: 100%;
  }
}

.product__slider .l-column__item {
  padding: 5.5%;
  transition: all 0.8s ease-in-out;
}
.product__slider .slick-arrow {
  position: absolute;
  top: 25%;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: 100%;
  overflow: hidden;
}
.product__slider .slick-prev {
  left: -60px;
  background-image: url(../../assets/images/icon_arrow_before.svg);
}
.product__slider .slick-next {
  right: -60px;
  background-image: url(../../assets/images/icon_arrow_next.svg);
}
@media (max-width: 768px) {
  .product__slider .l-column__item {
    width: 48%;
    margin: 0 0 13%;
    padding: 0;
  }
}

.l-topPage__news {
  background-color: #f4f4f4;
  padding: 70px 0;
}
.l-topPage__info {
  margin: 110px auto 156px;
}
@media (max-width: 640px) {
  .l-topPage__info {
    margin: 74px auto;
  }
}
.l-topPage__info .l-topPage__more, .l-topPage__news .l-topPage__more {
  position: initial;
  width: 406px;
  margin: 74px auto 0;
  max-width: calc(100% - 100px);
}
@media (max-width: 640px) {
  .l-topPage__info .l-topPage__more, .l-topPage__news .l-topPage__more {
    margin-top: 32px;
  }
}
.l-topPage__contact {
  margin: 0 auto 56px;
}

.news__title {
  text-align: center;
  font-size: 2.5rem;
}
.news__title .wrap {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  border: solid 1px #707070;
  background-color: #fff;
  padding: 6px;
  display: inline-block;
  font-weight: 600;
}
.news__title .wrap span {
  display: inline-block;
  border: solid 0.5px #707070;
  padding: 18px 24px;
  line-height: 1;
}
@media (max-width: 640px) {
  .news__title .wrap span {
    padding: 10px 12px;
  }
}
@media (max-width: 640px) {
  .news__title {
    font-size: 2rem;
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}
.news__list {
  display: flex;
  justify-content: center;
  margin: 65px auto 0;
  width: 1158px;
  max-width: calc(100% - 105px);
}
@media (max-width: 1024px) {
  .news__list {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    max-width: calc(100% - 20px);
  }
}
@media (max-width: 640px) {
  .news__list {
    margin-top: 42px;
  }
}
.news__item {
  width: 25%;
}
@media (max-width: 1024px) {
  .news__item {
    width: 45%;
  }
}
@media (max-width: 640px) {
  .news__item {
    width: 100%;
  }
}
.news__item:not(:last-child) {
  margin-right: 35px;
}
@media (max-width: 1024px) {
  .news__item:not(:last-child) {
    margin-right: 0;
  }
}
.news__item-figure {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.news__item-figure a {
  width: 100%;
  z-index: 1;
}
.news__item-figure a::before {
  content: "";
  display: inline-block;
  width: 51px;
  height: 36px;
  background-image: url(../../assets/images/ico_youtube.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: 0.3s;
}
.news__item-figure a:hover::before {
  transform: translate(-50%, -50%) scale(1.1);
}
.news__item-figure img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.news__item-info {
  line-height: 1.25;
  font-size: 1.4rem;
}
.news__item-info .title {
  font-weight: 600;
  margin: 20px 0 10px;
}
.news__item-info .detail .views::after {
  content: " ・ ";
}

.info__title {
  text-align: center;
}
.info__title .en {
  font-family: "Libre Baskerville", serif;
  font-size: 1.7rem;
}
@media (max-width: 640px) {
  .info__title .en {
    font-size: 1.4rem;
  }
}
.info__title .jp {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 3.5rem;
  font-weight: 600;
}
@media (max-width: 640px) {
  .info__title .jp {
    font-size: 2.5rem;
  }
}
.info__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
@media (max-width: 640px) {
  .info__list {
    flex-direction: column;
    margin-top: 12px;
  }
}
.info__item {
  width: 48%;
  border-bottom: solid 0.5px #C3C1C1;
  margin-top: 42px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (max-width: 640px) {
  .info__item {
    width: 100%;
    margin-top: 20px;
  }
}
.info__item::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: #C3C1C1;
  margin-bottom: 6px;
}
.info__item-date {
  font-size: 1.4rem;
  line-height: 1;
}
@media (max-width: 640px) {
  .info__item-date {
    font-size: 1.2rem;
  }
}
.info__item:nth-child(1) .info__item-date, .info__item:nth-child(2) .info__item-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info__item:nth-child(1) .info__item-date::after, .info__item:nth-child(2) .info__item-date::after {
  content: "NEW";
  display: inline-block;
  width: 40px;
  height: 17px;
  font-size: 1.1rem;
  background-color: #4BB98E;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 640px) {
  .info__item:nth-child(1) .info__item-date::after, .info__item:nth-child(2) .info__item-date::after {
    font-size: 1rem;
    width: 32px;
    height: 16px;
  }
}
.info__item-title {
  padding: 15px 0 20px;
  line-height: 1.25;
}
@media (max-width: 640px) {
  .info__item-title {
    font-size: 1.2rem;
  }
}

.contact__wrap {
  border: solid 0.5px #4BB98E;
  outline: solid 1px #4BB98E;
  outline-offset: 5px;
  margin: 6px;
  text-align: center;
}
.contact__title {
  margin-top: 30px;
}
.contact__title .en {
  font-size: 1.7rem;
}
.contact__title .jp {
  font-size: 2.5rem;
}
@media (max-width: 640px) {
  .contact__title .en {
    font-size: 1.4rem;
  }
  .contact__title .jp {
    font-size: 1.8rem;
  }
}
.contact__link {
  background-color: #4BB98E;
  color: #fff;
  border-radius: 5px;
  width: 780px;
  height: 70px;
  margin: 50px auto 62px;
  max-width: calc(100% - 80px);
}
@media (max-width: 640px) {
  .contact__link {
    margin: 30px auto;
    height: 60px;
  }
}
.contact__link a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-news {
  margin-bottom: 150px;
}
@media (max-width: 1120px) {
  .l-news {
    margin: 0 12px;
  }
}
@media (max-width: 768px) {
  .l-news {
    width: calc(100% - 24px);
    margin: 0 auto 80px;
  }
}
@media (max-width: 640px) {
  .l-news {
    width: 100%;
  }
}
.l-news .news__title .wrap, .l-news .news__title .wrap span {
  display: block;
}
.l-news .news__list {
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 60px 30px;
}
@media (max-width: 640px) {
  .l-news .news__list {
    max-width: calc(100% - 24px);
    gap: 30px;
  }
}
.l-news .news__item {
  width: 31.5%;
}
@media (max-width: 1120px) {
  .l-news .news__item {
    width: 30%;
  }
}
@media (max-width: 640px) {
  .l-news .news__item {
    width: 100%;
  }
}
.l-news .news__item:not(:last-child) {
  margin-right: 0;
}
.l-news .news__item:last-child {
  margin-right: auto;
}
.l-news .news__item .title {
  font-size: 1.6rem;
}

.l-contact {
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.25;
}
@media (max-width: 768px) {
  .l-contact {
    padding: 0 14px;
  }
}
.l-contact__top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media (max-width: 640px) {
  .l-contact__top {
    flex-direction: column;
  }
}
.l-contact__top .contactByPhone {
  border: 1px solid #707070;
  padding: 5px;
  max-width: 440px;
  width: 100%;
}
.l-contact__top .contactByPhone__wrap {
  text-align: center;
  border: 0.5px solid #707070;
  padding: 20px;
  font-weight: 500;
}
.l-contact__top .contactByPhone__wrap p:nth-child(2) {
  font-size: 4.2rem;
  margin: 18px 0;
}
@media (max-width: 640px) {
  .l-contact__top .contactByPhone__wrap p:nth-child(2) {
    font-size: 2.8rem;
    margin: 10px 0;
  }
}
.l-contact__top .contactHeader {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-right: 20px;
}
@media (max-width: 640px) {
  .l-contact__top .contactHeader {
    margin: 40px 20px 0;
  }
}
.l-contact__top .contactHeader__title .en {
  font-size: 1.7rem;
  margin-bottom: 16px;
}
@media (max-width: 640px) {
  .l-contact__top .contactHeader__title .en {
    font-size: 1.4rem;
    margin-bottom: 8px;
  }
}
.l-contact__top .contactHeader__title .jp {
  font-size: 2.5rem;
  font-weight: 500;
}
@media (max-width: 640px) {
  .l-contact__top .contactHeader__title .jp {
    font-size: 2rem;
  }
}
.l-contact__top .contactHeader__guide {
  font-size: 1.8rem;
}
@media (max-width: 640px) {
  .l-contact__top .contactHeader__guide {
    font-size: 1.6rem;
    margin-top: 20px;
  }
}
.l-contact__form {
  margin: 70px 0 140px;
}
@media (max-width: 640px) {
  .l-contact__form {
    margin: 30px 0 60px;
  }
}

.l-contact__form form table {
  background-color: #F0F8F6;
  width: 100%;
}
.l-contact__form form table tr:not(:last-child) {
  border-bottom: 2px solid #fff;
}
.l-contact__form form table tr:last-child th {
  vertical-align: top;
}
.l-contact__form form table tr th {
  padding: 32px 0 32px 54px;
  width: 30%;
}
.l-contact__form form table tr th span {
  font-size: 2.2rem;
  font-weight: 500;
}
@media (max-width: 768px) {
  .l-contact__form form table tr th span {
    font-size: 1.6rem;
  }
}
.l-contact__form form table tr td {
  width: 70%;
  padding: 32px 54px 32px 14px;
}
.l-contact__form form table tr td input, .l-contact__form form table tr td textarea {
  border: 1px solid #4BB98E;
  border-radius: 0;
  padding: 0.5em;
}
.l-contact__form form table tr td input {
  height: 50px;
  width: 70%;
}
@media (max-width: 768px) {
  .l-contact__form form table tr td input {
    width: 100%;
    height: 40px;
  }
}
.l-contact__form form table tr td textarea {
  width: 100%;
}
.l-contact__form form table tr .noticeTxt {
  font-size: 1.2rem;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .l-contact__form form table tr .noticeTxt {
    font-size: 1.1rem;
    margin-top: 8px;
  }
}
@media (max-width: 768px) {
  .l-contact__form form table tr th, .l-contact__form form table tr td {
    display: block;
    width: 100%;
  }
  .l-contact__form form table tr th {
    padding: 10px 20px;
  }
  .l-contact__form form table tr td {
    padding: 0 20px 10px;
  }
}
.l-contact__form form .formBtn {
  text-align: center;
  margin: 80px auto 0;
  width: 60%;
  color: #fff;
  border-radius: 5px;
  background-color: #4BB98E;
  transition: 0.3s ease 0s;
}
@media (max-width: 640px) {
  .l-contact__form form .formBtn {
    margin-top: 40px;
  }
}
.l-contact__form form .formBtn input {
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
  padding: 24px;
  cursor: pointer;
}
@media (max-width: 640px) {
  .l-contact__form form .formBtn input {
    font-size: 1.6rem;
    padding: 16px;
  }
}
.l-contact__form form .formBtn:hover {
  opacity: 0.7;
}
.l-contact__form form .formBtn .wpcf7-spinner {
  display: none;
}

.l-infoList {
  margin-bottom: 150px;
}
@media (max-width: 1120px) {
  .l-infoList {
    padding: 0 12px;
  }
}
@media (max-width: 640px) {
  .l-infoList {
    margin-bottom: 80px;
  }
}
.l-infoList__title .en {
  font-size: 1.7rem;
  font-family: "Libre Baskerville", serif;
}
@media (max-width: 640px) {
  .l-infoList__title .en {
    font-size: 1.4rem;
  }
}
.l-infoList__title .jp {
  font-size: 3.5rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-weight: 600;
}
@media (max-width: 640px) {
  .l-infoList__title .jp {
    font-size: 2.4rem;
  }
}
.l-infoList__wrap {
  margin-top: 65px;
  padding: 0 6px;
}
@media (max-width: 640px) {
  .l-infoList__wrap {
    margin-top: 30px;
  }
}

.infoList__list {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 46px;
}
@media (max-width: 768px) {
  .infoList__list {
    gap: 32px;
    flex-flow: nowrap column;
  }
}
.infoList__item {
  width: calc(50% - 23px);
  padding: 24px 20px 20px 24px;
  border: 0.5px solid #707070;
  outline: 1px solid #707070;
  outline-offset: 4px;
  transition: 0.3s ease 0s;
}
@media (max-width: 768px) {
  .infoList__item {
    width: 100%;
    padding: 12px 10px 10px 12px;
  }
}
.infoList__item:hover {
  border: 0.5px solid #4BB98E;
  outline: 1px solid #4BB98E;
}
.infoList__item:last-child {
  margin-right: auto;
}
.infoList__item-date {
  font-size: 1.4rem;
}
.infoList__item-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 12px 0 22px;
}
.infoList__item-title::after {
  content: "";
  display: inline-block;
  width: 40px;
  height: 17px;
  margin: 8px 0 0 40px;
}
@media (max-width: 640px) {
  .infoList__item-title::after {
    width: 32px;
    height: 16px;
  }
}
.infoList__item-title.newIcon::after {
  content: "NEW";
  display: inline-block;
  width: 40px;
  height: 17px;
  font-size: 1.1rem;
  background-color: #4BB98E;
  color: #fff;
  font-weight: 500;
  border-radius: 2px;
  text-align: center;
  line-height: 1.5;
  padding: 0 8px;
  margin: 8px 0 0 40px;
}
@media (max-width: 640px) {
  .infoList__item-title.newIcon::after {
    font-size: 1rem;
    width: 38px;
    height: 16px;
  }
}
.infoList__item-btn {
  background-color: #707070;
  width: 133px;
  height: 32px;
  margin-left: auto;
}
.infoList__item-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/icon_arrow_right.svg);
  background-repeat: no-repeat;
  background-size: 14px 4px;
  background-position: right 16px center;
  background-color: #fff;
  border-radius: 7px;
  border: solid 1px #707070;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 1.2rem;
}
.infoList__item-btn a span {
  margin-right: 0.4rem;
}

.pager {
  text-align: center;
  margin-top: 100px;
}
@media (max-width: 640px) {
  .pager {
    margin-top: 50px;
  }
}
.pager a, .pager p {
  display: inline-block;
  width: 30px;
  height: 30px;
}
@media (max-width: 640px) {
  .pager a, .pager p {
    width: 26px;
    height: 26px;
  }
}
.pager a {
  margin: 0 8px;
}
.pager a.current {
  background-color: #4BB98E;
  color: #fff;
}
.pager a.prev {
  width: 6px;
  height: 10px;
  background-image: url(../../assets/images/ico_pager-left.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.pager a.next {
  width: 6px;
  height: 10px;
  background-image: url(../../assets/images/ico_pager-right.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.l-infoDetail {
  max-width: 1106px;
  width: 100%;
  margin: 0 auto 150px;
}
@media (max-width: 1024px) {
  .l-infoDetail {
    width: calc(100% - 26px);
  }
}
@media (max-width: 640px) {
  .l-infoDetail {
    margin-bottom: 75px;
  }
}
.l-infoDetail__top {
  text-align: center;
  border: 1px solid #707070;
  outline: 0.5px solid #707070;
  outline-offset: -8px;
  line-height: 1;
  padding: 40px;
}
@media (max-width: 640px) {
  .l-infoDetail__top {
    padding: 20px;
  }
}
.l-infoDetail__top .en {
  font-family: "Libre Baskerville", serif;
  font-size: 1.7rem;
  margin-bottom: 16px;
}
@media (max-width: 640px) {
  .l-infoDetail__top .en {
    font-size: 1.4rem;
    margin-bottom: 8px;
  }
}
.l-infoDetail__top .jp {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 3.5rem;
  font-weight: 600;
}
@media (max-width: 640px) {
  .l-infoDetail__top .jp {
    font-size: 2rem;
  }
}
.l-infoDetail__wrap {
  margin-top: 60px;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap {
    margin-top: 30px;
  }
}
.l-infoDetail__wrap article h1 {
  font-size: 2.2rem;
  border-top: solid 2px #4BB98E;
  border-bottom: solid 2px #4BB98E;
  margin: 4px 0;
  position: relative;
  padding: 14px 0;
  color: #4BB98E;
  font-weight: 700;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap article h1 {
    font-size: 1.8rem;
  }
}
.l-infoDetail__wrap article h1::after {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: solid 1px #4BB98E;
  position: absolute;
  left: 0px;
  top: -6px;
}
.l-infoDetail__wrap article h1::before {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: solid 1px #4BB98E;
  position: absolute;
  left: 0px;
  bottom: -6px;
}
.l-infoDetail__wrap article h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #4BB98E;
  border-bottom: solid 2px #4BB98E;
  padding: 14px 0;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap article h2 {
    font-size: 1.6rem;
    padding: 10px 0;
  }
}
.l-infoDetail__wrap article h3 {
  font-weight: 700;
  padding: 14px 0;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap article h3 {
    font-size: 1.4rem;
    padding: 10px 0;
  }
}
.l-infoDetail__wrap article p {
  margin: 10px 0;
}
.l-infoDetail__wrap article .btn {
  text-align: center;
  height: 100%;
  margin-top: 72px;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap article .btn {
    margin-top: 36px;
  }
}
.l-infoDetail__wrap article .btn span {
  color: #4BB98E;
  border: solid 1px #4BB98E;
  padding: 16px;
  display: inline-block;
  width: calc(100% - 60px);
  max-width: 470px;
}
@media (max-width: 640px) {
  .l-infoDetail__wrap article .btn span {
    padding: 12px;
  }
}

.thanks {
  width: calc(100% - 20px);
  margin: 80px auto 160px;
}
@media (max-width: 640px) {
  .thanks {
    margin: 40px auto 80px;
  }
}
.thanks h2 {
  font-size: 3.2rem;
  font-weight: 600;
}
@media (max-width: 640px) {
  .thanks h2 {
    font-size: 2.4rem;
  }
}
.thanks a {
  color: #4BB98E;
  font-weight: 700;
}

.l-footer {
  width: 100%;
  background-color: #2B2B2B;
}
.l-footer__wrapper {
  display: flex;
  justify-content: space-between;
  width: 1366px;
  min-height: 185px;
  margin: auto;
  padding: 40px 90px 40px 150px;
}
.l-footer__navi {
  display: flex;
  color: #fff;
}
.l-footer__naviItem {
  width: 120px;
}
.l-footer__naviItem a {
  display: block;
  font-size: 1.6rem;
  line-height: 2;
}
.l-footer__policy {
  width: 150px;
}
.l-footer__policy a {
  display: block;
  font-size: 1.4rem;
  line-height: 2;
  white-space: nowrap;
}
.l-footer__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.l-footer__logo {
  display: block;
  width: 211px;
  height: 50px;
}
.l-footer__logo img {
  height: 50px;
}
.l-footer__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 1.3rem;
  background-color: #fff;
}
@media (max-width: 1365px) {
  .l-footer__wrapper {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .l-footer__wrapper {
    position: relative;
    min-height: 120px;
    padding: 20px 23px;
  }
  .l-footer__naviItem {
    width: 65px;
  }
  .l-footer__naviItem a {
    font-size: 1.1rem;
  }
  .l-footer__policy a {
    font-size: 1.1rem;
  }
  .l-footer__inner {
    position: absolute;
    height: 24px;
    bottom: 23px;
    right: 23px;
  }
  .l-footer__logo {
    width: 104px;
  }
  .l-footer__logo img {
    height: 24px;
  }
  .l-footer__copyright {
    height: 35px;
    font-size: 0.9rem;
  }
}

.l-sns {
  margin-bottom: 32px;
}
.l-sns__item {
  display: inline-block;
  width: 25px;
  height: 25px;
}
.l-sns__item + .l-sns__item {
  margin-left: 15px;
}
.l-sns__item img {
  width: 100%;
  height: 100%;
}
@media (max-width: 640px) {
  .l-sns {
    margin-bottom: 30px;
  }
  .l-sns__item {
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  .l-sns__item + .l-sns__item {
    margin-left: 10px;
  }
}